import { createContext, useState, useEffect } from 'react';
import AuthService from './AuthService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [congrats, setCongrats] = useState(null);

  // useEffect to handle state update
  useEffect(() => {
    const localStorageUser = localStorage.getItem('user');
    if (localStorageUser) {
        setUser(localStorageUser);
    }
  }, []);

  const login = async (email, password) => {
    try {
      const user = await AuthService.login(email, password);
      if(user !== email){
        setError(user);
      } else{
        setUser(user);
        setCongrats('Login successful!')
      }
    } catch (error) {
      setError(error)
    }
  };

  const register = async (username, password1, password2, email) => {
    try {
      const user = await AuthService.register(username, password1, password2, email);
      if(user !== email){
        setError(user);
      } else{
        setUser(user);
        setCongrats('Your account was created!')
      }
    } catch (error){
      setError(user);
    }
  };

  const logout = () => {
    AuthService.logout();
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, register, logout, error, setError, congrats, setCongrats }}>
      {children}
    </AuthContext.Provider>
  );
};