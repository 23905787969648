import React, { useContext } from 'react'
import CodeEditor from '../../components/QuestionScreen/CodeEditor';
import ResponseChat from '../../components/QuestionScreen/ResponseChat';
import TestsTable from '../../components/QuestionScreen/TestsTable/TestsTable';
import CompilationErrorCard from '../../components/QuestionScreen/CompilationErrorCard/CompilationErrorCard';
import TheoryButton from '../../components/QuestionScreen/TheoryButton';
import NavBar from '../../components/NavBar/NavBar';
import Spinner from 'react-bootstrap/Spinner';
import loadingGif from '../../images/gif/compilationError.gif'
import { AuthContext } from '../../AuthContext.js'; 
import { useQuestionScreen } from './hooks/useQuestionScreen';
import correctSound from './sound/correct.mp3'

import '../../App.css'

import { useParams } from 'react-router-dom';
import MobileAlert from '../../components/MobileAlert';
import ChangeExerciseButton from '../../components/QuestionScreen/ChangeExerciseButton';
import MessageVideoSolution from '../../components/QuestionScreen/CongratsMessage/CongratsMessage';
import './QuestionScreen.css'
import SolutionButton from '../../components/QuestionScreen/SolutionButton';

function QuestionScreen() {
    const { id } = useParams();

    const { user } = useContext(AuthContext);

    const {
        title,
        loadingProblemStatement,
        question,
        textCodeEditor,
        handleChange,
        exercisesList,
        runCode,
        hasCompiled,
        loadingCompileError,
        loadingTests,
        compileErrorMessage,
        unitTest,
        passAllTests,
        loading,
        answerText,
        chatTookTooLong,
        studentCorrectSolution,
        needChatGPT,
        progress,
        language,
        errorMessage
    } = useQuestionScreen(id, user);
    
    // Render the code editor component with the "text" state variable and "handleChange" function
    return (
        <div className='screen-container'>
            <NavBar />

            <MobileAlert />
        
            <div className='container-question-screen-file'>
                {/* Conditionally render the audio element */}
                {passAllTests === true && <audio src={correctSound} autoPlay />}

                {/* Render the CodeEditor and Run button */}
                <div className='container-with-question-and-code'>
                    {/* Display the question name */}
                    {loadingProblemStatement && (
                        <div className="spinner-container">
                            <Spinner animation="border" role="status">
                            </Spinner>
                        </div>
                    )}
                    {/* Display the question name */}
                    <div className='btn-question-screen-title-solution'>
                        <h2 className='question-title'>{title}</h2>
                        <SolutionButton problem_id={id} title={title}/>
                    </div>
                    {/* Display the question statement */}
                    <h6 className='question-statement' style={{ whiteSpace: "pre-wrap" }}>{question}</h6>
                    
                    {/* Display button to Run the code */}
                    <div className='btn-question-screen-div'>
                        <TheoryButton problem_id={id} />
                        <ChangeExerciseButton id={id} exercisesList={exercisesList} />
                        <button className='btn-run' onClick={runCode}>Run</button>
                    </div>

                    {/* Display CodeEditor */}
                    <CodeEditor language={language} value={textCodeEditor} onChange={handleChange} />
                </div>
                
                <div className='container-with-answer-and-tests'>
                    {/* Rendering the compile error if it exists */}
                    
                    {hasCompiled === false && loadingCompileError === false && !loadingTests ? <CompilationErrorCard compileErrorMessage={compileErrorMessage} /> : ""}


                    {/* Loading tests table, compilation or congrats message */}
                    {loadingTests && (
                        <img src={loadingGif} alt='Loading...' className='gif-size'/>
                    )}
                    {!needChatGPT && hasCompiled && unitTest && !loadingTests && !passAllTests && <TestsTable tests={unitTest} title={title}/>}

                    {/* Rendering the congratulation message with video solution */}
                    {!needChatGPT && hasCompiled && unitTest && !loadingTests && passAllTests && <MessageVideoSolution problem_id={id} unitTest={unitTest} title={title}/>}

                    { // If the exercise doesn't have unit test
                        needChatGPT && answerText &&
                        ( // Start the loading bar
                            loading ? 
                            <div className='loading-bar-div'>
                                <h6>{progress}%</h6>
                                <div className='loading-bar-progress' style={{ width: `${progress}%` }}></div>
                            </div>
                            :
                            (
                                // If it's not loading and the student answer correctly
                                studentCorrectSolution ?
                                (<div className='div-show-paragraph'>
                                    <div className='svg-congrats'>
                                        <svg width="39" height="20" viewBox="0 0 39 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="39" height="20" rx="10" fill="#28AB25"/>
                                        </svg>
                                        <p className='p-congrats'>Congratulations,</p>
                                    </div>
                                    <p className='p-passed-tests'>you passed all tests.</p>
                                </div>)
                            :
                                // If the answer of the student is incorrect
                                (<div className='div-show-paragraph'>
                                    <div className='svg-congrats'>
                                        <svg width="39" height="20" viewBox="0 0 39 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="39" height="20" rx="10" fill="#FF0000"/>
                                        </svg>
                                        <p className='p-congrats'>Unfortunately,</p>
                                    </div>
                                    <p className='p-passed-tests'>you didn't passed all tests.</p>
                                </div>)
                            )
                        )
                    }

                    {/* Rendering loading while answerText is not ready */}
                    {!needChatGPT && loading && 
                        <div className='loading-bar-div'>
                            <h6>{progress}%</h6>
                            <div className='loading-bar-progress' style={{ width: `${progress}%` }}></div>
                        </div>
                    }

                    {/* Rendering the answer if it exists */}
                    {answerText && loading === false && chatTookTooLong === false && <ResponseChat text={answerText} studentCorrectSolution={studentCorrectSolution} />}
                    {/* Display error message if it exists */}
                    {errorMessage && (
                        <div className="error-message" style={{ color: 'white', textAlign: 'center', margin: '10px' }}>
                            {errorMessage}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default QuestionScreen