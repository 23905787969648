import React from 'react'
import UnitCard from './UnitCard'
import WeekButton from './WeekButton'
import WeekButtonDeactive from './WeekButtonDeactive'

// Images
import functionsVariables from '../../images/Tree/functionsVariables.png'
import Strings from '../../images/Tree/Strings.png'
import ProblemSet from '../../images/Tree/ProblemSet.png'
import Conditionals from '../../images/Tree/Conditionals.png'
import Lists from '../../images/Tree/Lists.png'
import Loops from '../../images/Tree/Loops.png'
import Dictionaries from '../../images/Tree/Dictionaries.png'
import Exceptions from '../../images/Tree/Exceptions.png'
import Libraries from '../../images/Tree/Libraries.png'
import UnitTest from '../../images/Tree/UnitTest.png'
import Files from '../../images/Tree/Files.png'
import CS50xWorldCup from '../../images/Tree/CS50xWorldCup.png'
import CS50xDNA from '../../images/Tree/CS50xDNA.png'
import RegularExpressions from '../../images/Tree/RegularExpression.png'
import ObjectOriented from '../../images/Tree/ObjectOriented.png'
import Python from '../../images/Tree/Python.png'

function PythonTree() {
  return (
    <div className='tree'>
        <div className='unit-content'>
            <UnitCard number='0' title='Functions and Variables' primaryColor="#EBB400" secondaryColor="#9847FF" terciaryColor="#6621BD" goToLink={"https://cs50.harvard.edu/python/2022/notes/0/"} />
            <div className='unit-content-weeks'>
                <div className='column-4'><WeekButton title='Functions and Variables' image={functionsVariables} primaryColor="#9847FF" secondaryColor="#FDB500" terciaryColor="#6621BD" goToLink={'/week/0'} /></div>
                <div className='column-5'><WeekButton title='Strings 1' image={Strings} primaryColor="#9847FF" secondaryColor="#FDB500" terciaryColor="#6621BD" goToLink={'/week/1'} /></div>    
                <div className='column-6'><WeekButton title='Problem Set 0' image={ProblemSet} primaryColor="#9847FF" secondaryColor="#FDB500" terciaryColor="#6621BD" goToLink={'https://cs50.harvard.edu/python/2022/psets/0/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='1' title='Conditionals' primaryColor="#15129E" secondaryColor="#EBB400" terciaryColor="#A2821A" goToLink={"https://cs50.harvard.edu/python/2022/notes/1/"} />
            <div className='unit-content-weeks'>
                <div className='column-7'><WeekButton title='Conditionals 1' image={Conditionals} primaryColor="#EBB400" secondaryColor="#15129E" terciaryColor="#A1821A" goToLink={'/week/2'} /></div>
                <div className='column-8'><WeekButton title='Conditionals 2' image={Conditionals} primaryColor="#EBB400" secondaryColor="#15129E" terciaryColor="#A1821A" goToLink={'/week/3'} /></div>
                <div className='column-7'><WeekButton title='Lists 1' image={Lists} primaryColor="#EBB400" secondaryColor="#15129E" terciaryColor="#A1821A" goToLink={'/week/4'} /></div>    
                <div className='column-6'><WeekButton title='Problem Set 1' image={ProblemSet} primaryColor="#EBB400" secondaryColor="#15129E" terciaryColor="#A1821A" goToLink={'https://cs50.harvard.edu/python/2022/psets/1/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='2' title='Loops' primaryColor="#1DCA24" secondaryColor="#000000" terciaryColor="#6F6F6F" goToLink={"https://cs50.harvard.edu/python/2022/notes/2/"} />
            <div className='unit-content-weeks'>
                <div className='column-5'><WeekButton title='Loops' image={Loops} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'/week/5'} /></div>
                <div className='column-4'><WeekButton title='Strings 2' image={Strings} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'/week/6'} /></div>
                <div className='column-3'><WeekButton title='Lists 2' image={Lists} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'/week/7'} /></div>
                <div className='column-2'><WeekButton title='Dictionaries' image={Dictionaries} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'/week/8'} /></div>
                <div className='column-1'><WeekButton title='Problem Set 2' image={ProblemSet} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'https://cs50.harvard.edu/python/2022/psets/2/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='3' title='Exceptions' primaryColor="#CF07D3" secondaryColor="#7FDF7D" terciaryColor="#2A9627" goToLink={"https://cs50.harvard.edu/python/2022/notes/3/"} />
            <div className='unit-content-weeks'>  
                <div className='column-2'><WeekButton title='Exceptions' image={Exceptions} primaryColor="#7FDF7D" secondaryColor="#CF07D3" terciaryColor="#2A9627" goToLink={'https://classroom.github.com/a/iIRriLn8'}/></div>
                <div className='column-3'><WeekButton title='Problem Set 3' image={ProblemSet} primaryColor="#7FDF7D" secondaryColor="#CF07D3" terciaryColor="#2A9627" goToLink={'https://cs50.harvard.edu/python/2022/psets/3/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='4' title='Libraries' primaryColor="#3F8286" secondaryColor="#FF6B00" terciaryColor="#BA601E" goToLink={"https://cs50.harvard.edu/python/2022/notes/4/"} />
            <div className='unit-content-weeks'>  
                <div className='column-4'><WeekButton title='Libraries' image={Libraries} primaryColor="#FF6B00" secondaryColor="#3F8286" terciaryColor="#BA601E" goToLink={'https://classroom.github.com/a/eCnFcZ2T'}/></div>
                <div className='column-5'><WeekButton title='Problem Set 4' image={ProblemSet} primaryColor="#FF6B00" secondaryColor="#3F8286" terciaryColor="#BA601E" goToLink={'https://cs50.harvard.edu/python/2022/psets/4/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='5' title='Unit Test' primaryColor="#EBB400" secondaryColor="#9847FF" terciaryColor="#6621BD" goToLink={"https://cs50.harvard.edu/python/2022/notes/5/"} />
            <div className='unit-content-weeks'>  
                <div className='column-6'><WeekButton title='Unit Test' image={UnitTest} primaryColor="#9847FF" secondaryColor="#FDB500" terciaryColor="#6621BD" goToLink={'https://classroom.github.com/a/WePCSVwc'}/></div>
                <div className='column-7'><WeekButton title='Problem Set 5' image={ProblemSet} primaryColor="#9847FF" secondaryColor="#FDB500" terciaryColor="#6621BD" goToLink={'https://cs50.harvard.edu/python/2022/psets/5/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='6' title='File I/O' primaryColor="#15129E" secondaryColor="#EBB400" terciaryColor="#A2821A" goToLink={"https://cs50.harvard.edu/python/2022/notes/6/"} />
            <div className='unit-content-weeks'>
                <div className='column-8'><WeekButton title='File I/O' image={Files} primaryColor="#EBB400" secondaryColor="#15129E" terciaryColor="#A2821A" goToLink={'https://classroom.github.com/a/Wgp3fT9r'} /></div>
                <div className='column-7'><WeekButton title='Lab6: World Cup' image={CS50xWorldCup} primaryColor="#EBB400" secondaryColor="#15129E" terciaryColor="#A2821A" goToLink={'https://cs50.harvard.edu/x/2023/labs/6/'} /></div>
                <div className='column-6'><WeekButton title='PSET6: DNA' image={CS50xDNA} primaryColor="#EBB400" secondaryColor="#15129E" terciaryColor="#A2821A" goToLink={'https://cs50.harvard.edu/x/2023/psets/6/dna/'} /></div>
                <div className='column-5'><WeekButton title='Problem Set 6' image={ProblemSet} primaryColor="#EBB400" secondaryColor="#15129E" terciaryColor="#A2821A" goToLink={'https://cs50.harvard.edu/python/2022/psets/6/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='7' title='Regular Expressions' primaryColor="#1DCA24" secondaryColor="#000000" terciaryColor="#6F6F6F" goToLink={"https://cs50.harvard.edu/python/2022/notes/7/"} />
            <div className='unit-content-weeks'>   
                <div className='column-4'><WeekButton title='Regular Expressions' image={RegularExpressions} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'/week/9'} /></div>
                <div className='column-3'><WeekButton title='Problem Set 7' image={ProblemSet} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'https://cs50.harvard.edu/python/2022/psets/7/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='8' title='Object-Oriented Programming' primaryColor="#CF07D3" secondaryColor="#7FDF7D" terciaryColor="#2A9627" goToLink={"https://cs50.harvard.edu/python/2022/notes/8/"} />
            <div className='unit-content-weeks'>  
                <div className='column-2'><WeekButton title='Object-Oriented Programming' image={ObjectOriented} primaryColor="#7FDF7D" secondaryColor="#CF07D3" terciaryColor="#2A9627" goToLink={'https://classroom.github.com/a/9ThkMUIh'}/></div>
                <div className='column-1'><WeekButton title='Problem Set 8' image={ProblemSet} primaryColor="#7FDF7D" secondaryColor="#CF07D3" terciaryColor="#2A9627" goToLink={'https://cs50.harvard.edu/python/2022/psets/8/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='9' title='Review and Final Project' primaryColor="#3F8286" secondaryColor="#FF6B00" terciaryColor="#BA601E" goToLink={"https://cs50.harvard.edu/python/2022/notes/9/"} />
            <div className='unit-content-weeks'>  
                <div className='column-2'><WeekButton title='PSET6: Mario Less' image={ProblemSet} primaryColor="#FF6B00" secondaryColor="#3F8286" terciaryColor="#BA601E" goToLink={'https://cs50.harvard.edu/x/2023/psets/6/mario/less/'}/></div>
                <div className='column-3'><WeekButton title='PSET6: Mario More' image={ProblemSet} primaryColor="#FF6B00" secondaryColor="#3F8286" terciaryColor="#BA601E" goToLink={'https://cs50.harvard.edu/x/2023/psets/6/mario/more/'}/></div>
                <div className='column-4'><WeekButton title='PSET6: Cash' image={ProblemSet} primaryColor="#FF6B00" secondaryColor="#3F8286" terciaryColor="#BA601E" goToLink={'https://cs50.harvard.edu/x/2023/psets/6/cash/'}/></div>
                <div className='column-5'><WeekButton title='PSET6: Credit' image={ProblemSet} primaryColor="#FF6B00" secondaryColor="#3F8286" terciaryColor="#BA601E" goToLink={'https://cs50.harvard.edu/x/2023/psets/6/credit/'}/></div>
                <div className='column-6'><WeekButton title='PSET6: Readability' image={ProblemSet} primaryColor="#FF6B00" secondaryColor="#3F8286" terciaryColor="#BA601E" goToLink={'https://cs50.harvard.edu/x/2023/psets/6/readability/'}/></div>
                <div className='column-7'><WeekButton title='Final Project' image={Python} primaryColor="#FF6B00" secondaryColor="#3F8286" terciaryColor="#BA601E" goToLink={'https://cs50.harvard.edu/python/2022/project/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='10' title='SQL' primaryColor="#EBB400" secondaryColor="#9847FF" terciaryColor="#6621BD" goToLink={"https://cs50.harvard.edu/x/2023/notes/7/"} />
            <div className='unit-content-weeks'>
                <div className='column-8'><WeekButton title='SQLBolt' image={ProblemSet} primaryColor="#9847FF" secondaryColor="#FDB500" terciaryColor="#6621BD" goToLink={'https://sqlbolt.com/'} /></div>
                <div className='column-7'><WeekButton title='LAB7: Songs' image={ProblemSet} primaryColor="#9847FF" secondaryColor="#FDB500" terciaryColor="#6621BD" goToLink={'https://cs50.harvard.edu/x/2023/labs/7/'} /></div>    
                <div className='column-6'><WeekButton title='PSET7: Movies' image={ProblemSet} primaryColor="#9847FF" secondaryColor="#FDB500" terciaryColor="#6621BD" goToLink={'https://cs50.harvard.edu/x/2023/psets/7/movies/'}/></div>
                <div className='column-5'><WeekButton title='PSET7: Hall of Prophecy' image={ProblemSet} primaryColor="#9847FF" secondaryColor="#FDB500" terciaryColor="#6621BD" goToLink={'https://cs50.harvard.edu/x/2023/problems/7/prophecy/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='11' title='HTML, CSS & JavaScript' primaryColor="#15129E" secondaryColor="#EBB400" terciaryColor="#A2821A" goToLink={"https://cs50.harvard.edu/x/2023/notes/8/"} />
            <div className='unit-content-weeks'>
                <div className='column-4'><WeekButton title='ToDo App (HTML & JS)' image={ProblemSet} primaryColor="#EBB400" secondaryColor="#15129E" terciaryColor="#A1821A" goToLink={'https://classroom.github.com/a/9hULtpuW'} /></div>
                <div className='column-3'><WeekButtonDeactive image={ProblemSet} /></div>
                <div className='column-2'><WeekButtonDeactive image={ProblemSet} /></div>    
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='12' title='Flask' primaryColor="#1DCA24" secondaryColor="#000000" terciaryColor="#6F6F6F" goToLink={"https://cs50.harvard.edu/x/2023/notes/9/"} />
            <div className='unit-content-weeks'>
                <div className='column-1'><WeekButton title='ToDo App (Flask)' image={ProblemSet} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'https://classroom.github.com/a/ArBP7U4e'} /></div>
                <div className='column-2'><WeekButtonDeactive image={ProblemSet} /></div>
                <div className='column-3'><WeekButtonDeactive image={ProblemSet} /></div>
                <div className='column-4'><WeekButton title='PSET9: Finance' image={ProblemSet} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'https://cs50.harvard.edu/x/2023/psets/9/finance/'} /></div>
                <div className='column-5'><WeekButton title='Project 0: Search' image={ProblemSet} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'https://cs50.harvard.edu/web/2020/projects/0/search/'} /></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='13' title='Django' primaryColor="#CF07D3" secondaryColor="#7FDF7D" terciaryColor="#2A9627" goToLink={"https://cs50.harvard.edu/web/2020/notes/3/"} />
            <div className='unit-content-weeks'>  
                <div className='column-6'><WeekButton title='ToDo App (Django)' image={ProblemSet} primaryColor="#7FDF7D" secondaryColor="#CF07D3" terciaryColor="#2A9627" goToLink={'https://classroom.github.com/a/wgIHngmH'}/></div>
                <div className='column-7'><WeekButton title='Project 1: Wiki' image={ProblemSet} primaryColor="#7FDF7D" secondaryColor="#CF07D3" terciaryColor="#2A9627" goToLink={'https://cs50.harvard.edu/web/2020/projects/1/wiki/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='14' title='Django with SQL' primaryColor="#3F8286" secondaryColor="#FF6B00" terciaryColor="#BA601E" goToLink={"https://cs50.harvard.edu/web/2020/notes/4/"} />
            <div className='unit-content-weeks'>  
                <div className='column-8'><WeekButtonDeactive image={ProblemSet} /></div>
                <div className='column-7'><WeekButton title='Project 2: Commerce' image={ProblemSet} primaryColor="#FF6B00" secondaryColor="#3F8286" terciaryColor="#BA601E" goToLink={'https://cs50.harvard.edu/web/2020/projects/2/commerce/'}/></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='15' title='JavaScript' primaryColor="#EBB400" secondaryColor="#9847FF" terciaryColor="#6621BD" goToLink={"https://cs50.harvard.edu/web/2020/notes/5/"} />
            <div className='unit-content-weeks'>
                <div className='column-6'><WeekButtonDeactive image={ProblemSet} /></div>
                <div className='column-5'><WeekButton title='Project 3: Mail' image={ProblemSet} primaryColor="#9847FF" secondaryColor="#FDB500" terciaryColor="#6621BD" goToLink={'https://cs50.harvard.edu/web/2020/projects/3/mail/'} /></div>    
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='16' title='Testing, CI/CD' primaryColor="#15129E" secondaryColor="#EBB400" terciaryColor="#A2821A" goToLink={"https://cs50.harvard.edu/web/2020/notes/7/"} />
            <div className='unit-content-weeks'>
                <div className='column-4'><WeekButtonDeactive image={ProblemSet} /></div>
            </div>
        </div>
        <div className='unit-content'>
            <UnitCard number='17' title='React' primaryColor="#1DCA24" secondaryColor="#000000" terciaryColor="#6F6F6F" goToLink={"https://react.dev/learn"} />
            <div className='unit-content-weeks'>
                <div className='column-3'><WeekButton title='Tic Tac Toe' image={ProblemSet} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'https://react.dev/learn/tutorial-tic-tac-toe'} /></div>
                <div className='column-2'><WeekButtonDeactive image={ProblemSet} /></div>
                <div className='column-1'><WeekButton title='Project 4: Network' image={ProblemSet} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'https://cs50.harvard.edu/web/2020/projects/4/network/'}/></div>
                <div className='column-2'><WeekButton title='Final Project' image={ProblemSet} primaryColor="#000000" secondaryColor="#1DCA24" terciaryColor="#6F6F6F" goToLink={'https://cs50.harvard.edu/web/2020/projects/final/capstone/'} /></div>
            </div>
        </div>
        <div className='div-contribute'>
            <a href='/contribute'><button className='btn-contribute'>Contribute to CodingDors</button></a>
        </div>
    </div>
  )
}

export default PythonTree