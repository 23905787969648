import React from 'react'

function BootcampAISyllabus() {
  return (
    <div className='bootcamp-syllabus-div'>
        <div className='bootcamp-syllabus-header'>
            <svg xmlns="http://www.w3.org/2000/svg" width="134" height="207" viewBox="0 0 134 207" fill="none">
                <path d="M59.0863 66.5777C59.0863 65.4363 59.0863 64.5355 59.0863 63.6285C59.0863 45.0754 59.074 26.5162 59.0987 7.96313C59.1049 2.20039 64.2321 -1.48308 69.273 0.57769C72.2099 1.78083 73.8634 4.46475 73.8696 8.16056C73.8758 26.5594 73.8758 44.9644 73.8758 63.3632C73.8758 64.3442 73.8758 65.3314 73.8758 66.5592C78.9721 66.5592 83.8772 66.4729 88.7762 66.5777C104.053 66.8924 116.202 73.2968 125.08 85.7108C130.275 92.979 132.811 101.216 132.978 110.144C133.07 115.148 133.027 120.152 132.996 125.155C132.965 130.042 129.942 133.078 125.043 133.078C86.0182 133.09 46.9871 133.09 7.96207 133.078C3.06312 133.078 -0.108233 130.03 0.00282581 125.186C0.181755 117.505 -0.243982 109.7 1.01469 102.185C4.38966 82.0767 22.8193 66.8801 43.2357 66.5901C48.4432 66.516 53.6568 66.5777 59.0863 66.5777Z" fill="white"/>
                <path d="M33.043 140.599C55.3474 140.599 77.6086 140.599 99.8945 140.599C96.3529 152.513 81.1872 162.576 66.7248 162.65C49.0417 162.743 36.5537 150.582 33.043 140.599Z" fill="white"/>
                <path d="M59.0875 188.299C59.0875 184.837 59.0628 181.37 59.0936 177.908C59.1307 173.367 62.2033 170.091 66.4112 170.054C70.613 170.017 73.8337 173.306 73.8584 177.785C73.9015 184.942 73.9015 192.099 73.8584 199.256C73.8337 203.742 70.6253 207.024 66.4236 207C62.2403 206.969 59.1307 203.668 59.0936 199.152C59.069 195.536 59.0875 191.914 59.0875 188.299Z" fill="white"/>
                <path d="M117.386 189.946C114.906 189.897 112.889 189.021 111.482 187.12C107.052 181.135 102.622 175.144 98.3274 169.061C95.9644 165.717 96.7911 161.391 99.9748 159.004C103.121 156.641 107.619 157.06 110.075 160.293C114.628 166.278 119.021 172.393 123.39 178.519C125.099 180.919 125.099 183.579 123.692 186.145C122.316 188.644 120.114 189.86 117.386 189.946Z" fill="white"/>
                <path d="M15.589 189.928C9.93114 189.872 6.22301 183.579 9.39437 178.853C13.7133 172.424 18.3778 166.21 23.0978 160.059C25.4239 157.03 29.8169 156.721 32.8649 158.918C35.9992 161.176 37.0481 165.526 34.8146 168.74C30.4339 175.04 25.9299 181.271 21.1852 187.306C19.9944 188.817 17.7053 189.465 15.916 190.508C15.8173 190.31 15.7062 190.119 15.589 189.928Z" fill="white"/>
            </svg>
            <h1>Our <br/> <strong>Curriculum</strong></h1>
        </div>
        <p>
            By clicking the button below, you can access our bootcamp syllabus,
            which includes the schedule of topics and class days for the entire course.
        </p>
        <div className='bootcamp-syllabus-btn'>
            <a 
                href='https://sparkling-fork-b67.notion.site/Syllabus-Bootcamp-AI-Dors-Coding-School-1274b7631a3180159b0ced3c78287f09?pvs=4'
                target='_blank' 
                rel="noreferrer"
            >
                GET THE SYLLABUS
            </a>
        </div>
    </div>
  )
}

export default BootcampAISyllabus