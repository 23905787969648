import React from "react";
import './BenefitCards.css'

function BenefitCards( {titleColor, bodyColor, backgroundColor, title, body} ) {

    return (
        <div className={backgroundColor}>
            <i class="fa-solid fa-circle-check"></i>
            <div className={titleColor}>{title}</div>
            <div className={bodyColor}>{body}</div>
        </div>
    )
}

export default BenefitCards