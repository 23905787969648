import React from 'react'

function BootcampFAQCards({ question, answer }) {
  return (
    <div className='bootcamp-faq-card'>
        <h6>{question}</h6>
        <p>{answer}</p>
    </div>
  )
}

export default BootcampFAQCards