import React from 'react'
import "./CourseCard.css"
import {trackMixpanel } from '../../mixpanelWrapper';

function CourseCard( {cardTitle, cardImage, cardUrl} ) {

    const handleButtonClick = () => {
        trackMixpanel('ClickToCodeSolutionsCard');
    };

    return (
        <div className='code-solutions-course-card-container'>
            <p>{cardTitle[0]}<strong>{cardTitle[1]}</strong></p>
            <div className='code-solutions-card-container'>
                <img className='code-solutions-card-image' src={cardImage} alt=''></img>
            </div>
            <a onClick={handleButtonClick} href={cardUrl} className='code-solutions-card-button'>SOLUTIONS</a>
        </div>
    )
}

export default CourseCard
