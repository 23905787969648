import React, { useEffect } from 'react';
import NewNavBar from '../../components/NavBar/NavBarLandingPage';

const TidyCalEmbedAutomations = () => {
    useEffect(() => {
        // Load the TidyCal script
        const script = document.createElement('script');
        script.src = 'https://assets.tidycal.com/js/embed.js';
        script.async = true;
        document.body.appendChild(script);

        // Watch for user interaction with the TidyCal form
        const interval = setInterval(() => {
            const confirmationElement = document.querySelector('h1.fw-bold.h4.text-center'); // Replace with the actual class or ID
            if (confirmationElement) {
                // Push event to GTM
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'tidycal_booking_complete',
                });
                clearInterval(interval);
            }
        }, 500);

        return () => {
            // Cleanup
            document.body.removeChild(script);
            clearInterval(interval);
        };
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
            <NewNavBar />
            <div className="tidycal-embed" data-path="codingdors/30-minute-meeting-agency"></div>
        </div>
    );
};

export default TidyCalEmbedAutomations;
