import React from "react";
import './FrequentQuestionsCards.css'

function FrequentQuestionsCards( {height, faqtitle, faqanswer} ) {
    return (
        <div className="sales-page-faq-cards-container" style={height}>
            <div className="sales-page-faq-cards-title">
                {faqtitle}
            </div>
            <div className="sales-page-faq-cards-body">
                {faqanswer}
            </div>
        </div>
    )
}

export default FrequentQuestionsCards