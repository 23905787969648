import SecuringSystems from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/SecuringSystems.webp"
import SecuringAccounts from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/SecuringAccounts.webp"
import SecuringData from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/SecuringData.webp"

const CS50cybersecurity = [
    {
        "title":"Problem Set 0",
        "name":"Securing Accounts",
        "youtubelink":"https://www.youtube.com/embed/q76Jg4k2GC0?autoplay=1",
        "placeholderimage":SecuringAccounts,
        "githublink":"https://docs.google.com/document/d/1hjea9V3IywQf-1h8OCpBTL75iAVD-lRx/edit?usp=drive_link&ouid=111456114090744549687&rtpof=true&sd=true"
    },
    {
        "title":"Problem Set 1",
        "name":"Securing Data",
        "youtubelink":"https://www.youtube.com/embed/yUkyhYWiI38?autoplay=1",
        "placeholderimage":SecuringData,
        "githublink":"https://docs.google.com/document/d/1XWrz4cAUoTddWzRxkXut5jwv8_ZkTLnr/edit?usp=drive_link&ouid=111456114090744549687&rtpof=true&sd=true"
    },
    {
        "title":"Problem Set 2",
        "name":"Securing Systems",
        "youtubelink":"https://www.youtube.com/embed/LX3IZBvGvgo?autoplay=1",
        "placeholderimage":SecuringSystems,
        "githublink":"https://docs.google.com/document/d/1yCNKoGb8JZSW20MJ4CjchJDG_NFEKbv1/edit?usp=drive_link&ouid=111456114090744549687&rtpof=true&sd=true"
    }
]

export default CS50cybersecurity