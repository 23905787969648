import TestsTableCongrats from '../TestsTable/TestsTableCongrats';
import './CongratsMessage.css'

function MessageVideoSolution({ problem_id, unitTest, title }) {
  return (
    <div className='container-all-tests-passed'>
        { 
            <div>
              <div className='div-show-paragraph'>
                  <div className='svg-congrats'>
                      <svg width="39" height="20" viewBox="0 0 39 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="39" height="20" rx="10" fill="#28AB25"/>
                      </svg>
                      <p className='p-congrats'>Congratulations,</p>
                  </div>
                  <p className='p-passed-tests'>you passed all tests.</p>
              </div>
              <TestsTableCongrats tests={unitTest} title={title}/>
            </div>
        }
    </div>
  )
}

export default MessageVideoSolution