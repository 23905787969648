import List1 from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/List1.webp"
import String1 from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/String1.webp"
import String2 from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/String2.webp"
import Warmup1 from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Warmup1.webp"
import List2 from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/List2.webp"
import Logic1 from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Logic1.webp"
import Logic2 from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Logic2.webp"
import Warmup2 from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Warmup2.webp"

const CodingBat = [
    {
        "title":"CodingBat",
        "name":"Warmup-1",
        "youtubelink":"https://www.youtube.com/embed/B32_D0UgeGE?autoplay=1",
        "placeholderimage":Warmup1,
        "githublink":"https://app.kartra.com/download/e8abe05c2697f551e9176efc43e20f4db0d8fcaefcf9f7b997f54dda5bb40b69cd46809012f72107f8a2e929c9988cc14c664ed782ce46fb0dcc75ebc4547331r8663jsWVmL4pQGLKWMsi3cR8MhsvRja6804liKuETK4%2F7KBAjdOVL8SKAHdFEYMwzopQk3zK2D2zsFCAhwK%2BNFllYMU8E9eoj1bkyYes7Qp2S7k1MX3F0OIVD4%2BeMjk2uHKshuSRTdpdfBbJhV2IYy3kIuIr%2FPN6D9kDV%2BVSOU%3D?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=SWPgdCRKvQCp"
    },
    {
        "title":"CodingBat",
        "name":"Warmup-2",
        "youtubelink":"https://www.youtube.com/embed/xOHz-dTdldY?autoplay=1",
        "placeholderimage":Warmup2,
        "githublink":"https://app.kartra.com/download/1c85689930e7546686c304b87863d01a03b781881bf885d7ec94cd94db613c9e63bb8166d7ed5f595c59576142d3dd925be03618188faa03232de9b055823024PHlTnv741Kdg%2F0I%2F%2FffVa4sqAfsr1o0im1fXZb%2FNEfIVO%2BHsTiYRDvxLUqOnlcldxyeID7bFKrATs2AD4Af65Xb7gTOsLWXjU8LNn21lM1lMAwJyCwRREQUe2kD3rINpyarhe4d24icHBFCEARvuxp59d7CWXfomjANH%2FeJ0c2g%3D?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=SWPgdCRKvQCp"
    },
    {
        "title":"CodingBat",
        "name":"String-1",
        "youtubelink":"https://www.youtube.com/embed/085bio3fFI0?autoplay=1",
        "placeholderimage":String1,
        "githublink":"https://app.kartra.com/download/47c9d2e2030cffbb13520b892ab2e27fc467cc735c44922893f8e711c0724b2b2cdbe43668b661b77ca72d97a8ee62c4fb4350df67c3389aca3b065664dfbe4aVI5a2qStE38GIi%2FrG2TWL9YDBo0AdKgE58aLMJ4RaYy3zHPQmzpfexWDAUXFhuyLgebWIUlOT%2FSGaR7shgRoXpF4NQCqx5diu7FAsz7pyqvuj0%2Bx2AdjSwBAKgVrywpDGNPf%2Fz2zr8YtCnVPTZMEGSH0jHA9%2B%2BF8BqSBcAga6Zw%3D?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=SWPgdCRKvQCp"
    },
    {
        "title":"CodingBat",
        "name":"List-1",
        "youtubelink":"https://www.youtube.com/embed/1ptQzZyGLHk?autoplay=1",
        "placeholderimage":List1,
        "githublink":"https://app.kartra.com/download/806623fee3bb9764d5e3c83d37992e9a5059525914840aa1b97a4b715ced6612177fdab7969b0d57671344b081a74012119fd7bc19b04d173727d38d89a53ba9meeZGQ9OcOZX39PRBbUdk2wSTfbJr56jwpGFEBXOlLPgBYDQHVz3r%2BSeS%2FnWGvkifYiaXXABfDBqydB1YlHIM3kz1K3baLqYXiLEuA1YAA2rju5h%2B8DzVc97Vit71VnPliqcT7eirp6cKI2bo1aa5A%3D%3D?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=SWPgdCRKvQCp"
    },
    {
        "title":"CodingBat",
        "name":"Logic-1",
        "youtubelink":"https://www.youtube.com/embed/HgqSOiKdI30?autoplay=1",
        "placeholderimage":Logic1,
        "githublink":"https://app.kartra.com/download/6cf01f15289d4ce75c11ee25f93331bde38d27c6173d4dff6523063bc3c47a21a065d6c05f56f3dbbf6ed04c9235a4e7cd6643bc576aae2b3549d0c9e338d21eg4yExSbjuAfWloTl%2FwxMX0eY%2BVva3aTxKpJnmQuAoea9S%2F0BKGN23%2BFkjRhe7TZzVQo5PNr56Da%2Fbs7MjNRbRmyYgTqTAvNRcyt6S2veCi2z0uOPDJPxdVNChCfsN0M6lGLxNKIdD%2Fl6qtncm%2B0AZqwJxUmBf0Y14eTmdaPY9U8%3D?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=SWPgdCRKvQCp"
    },
    {
        "title":"CodingBat",
        "name":"Logic-2",
        "youtubelink":"https://www.youtube.com/embed/Pyl8bHpsT3Y?autoplay=1",
        "placeholderimage":Logic2,
        "githublink":"https://app.kartra.com/download/bf08b9bd9514c0afe1229d53b9ac6ed9387a53e82c107b9fb21cb520a15d9d405bf8422b3a7b33c1b9a90912266017ae2d0e868baab5b94d54492da1a234c751Bah6b16duhHVIvX6qT1RnVvk%2BuFdDRM%2BMnFg5KBeoYroTl3o7yqN0x7LdvjJPvHYybuDA%2FQFOtCcKqg1jeinikVyxBrqwiTAbHXtnzyk0d12ZkXs9a1vS8WR7ML2o89Glu9EIFGpz9RN%2FvaN0eDVlgWRl8cz60gjO7s9yQZDRLQ%3D?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=SWPgdCRKvQCp"
    },
    {
        "title":"CodingBat",
        "name":"String-2",
        "youtubelink":"https://www.youtube.com/embed/a1Zr6wWAht8?autoplay=1",
        "placeholderimage":String2,
        "githublink":"https://app.kartra.com/download/5f2d8edf474dd3b2445e8c989cd2b292d8532a3c70bea3069f62c2988a473d4e4b87197d087d7dd750cce5d60d7360245a78f3fbf8d811096b0dfc1d6ada343bn3RnDN0a3RGqDJXGXzmt5TbbMk3jlU8RMro19Qsatc8mggkLhQ1%2FPQfQt73BWKyDiesvB3kZMa41xY%2FnsGSnLMUKbJH2y9%2FuPemTLlTdsAM%2FSy0JKtwkISzSEHAx%2FphYWbkH7jcGHVTuj9sXJ%2BOT2oXVCGl9WM8RtVdkIg13fQg%3D?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=SWPgdCRKvQCp"
    },
    {
        "title":"CodingBat",
        "name":"List-2",
        "youtubelink":"https://www.youtube.com/embed/d7pT3TUkqVc?autoplay=1",
        "placeholderimage":List2,
        "githublink":"https://app.kartra.com/download/8687fbb50e0c479efdf4c16d401f3f3c8bbf5637c05f1f0b2dca3139640ab7d0310dfeeadc2a0e60adb2fe975abdff184f30d448a31d08dcba8fd1e6a115f2053XMqiNO5D6ripBmHoUDA6v6W%2BWLjO9na6c5l1HslCh3yTskqnTlBCM0MW%2FLkny0ufPrbTayjASTPIKTv8pMagRJ5WuPcWVnIdMrJZPdKbrhU2lkZuRWdSkKATI41iUbzSDdfjctbWllVmRw9KoxO9w%3D%3D?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=SWPgdCRKvQCp"
    }
]

export default CodingBat