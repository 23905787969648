import React from 'react'

function BootcampBenefits() {
  return (
    <div className='bootcamp-benefits-section'>
        <div className='bootcamp-benefits-card card-benefits-one'>
            <h5>Online Classes</h5>
            <h6>Hosted on Zoom</h6>
            <hr />
            <p>
                In our classes, we will focus on reviewing and reinforcing the key concepts of programming through a combination of challenging problem sets, group exercises, and pair programming activities.
            </p>
        </div>
        <div className='bootcamp-benefits-card card-benefits-two'>
            <h5>Exclusive Material</h5>
            <h6>Access to our membership</h6>
            <hr />
            <p>
                In our classes, we will focus on reviewing and reinforcing the key concepts taught in the CS50 lectures through a combination of challenging problem sets, group exercises, and pair programming activities.
            </p>
        </div>
        <div className='bootcamp-benefits-card card-benefits-three'>
            <h5>24/7 Support</h5>
            <h6>Join our Discord Community</h6>
            <hr />
            <p>
                A supportive and collaborative environment where students can connect with one another, ask questions, and share knowledge. You can use it as a supplement to your learning journey and connect with other learners from around the world.
            </p>
        </div>
        <div className='bootcamp-benefits-card card-benefits-four'>
            <h5>Weekly Classes</h5>
            <h6>3 hours in different days</h6>
            <hr />
            <p>
                To ensure that all students have access to necessary support and guidance, we will be offering 3 hours of group coaching to help you out in your questions. The classes will be on Tuesday and Thursday.
            </p>
        </div>

    </div>
  )
}

export default BootcampBenefits