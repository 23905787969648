import AdieuAdieu from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/AdieuAdieu.webp"
import BackToTheBank from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/BackToTheBank.webp"
import BitcoinPriceIndex from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/BitcoinPriceIndex.webp"
import CamelCase from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/CamelCase.webp"
import CokeMachine from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/CokeMachine.webp"
import CookieJar from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/CookieJar.webp"
import CS50PShirt from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/CS50PShirt.webp"
import CS50Shirtificate from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/CS50Shirtificate.webp"
import DeepThought from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/DeepThought.webp"
import Einstein from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Einstein.webp"
import Emojize from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Emojize.webp"
import FelipesTaqueria from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/FelipesTaqueria.webp"
import FileExtension from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/FileExtension.webp"
import FinalProject from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/FinalProject.webp"
import FrankIanAndGleensLetters from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/FrankIanAndGleensLetters.webp"
import FuelGauge from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/FuelGauge.webp"
import GroceryList from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/GroceryList.webp"
import GuessingGame from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/GuessingGame.webp"
import HomeFederalSavingBanks from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/HomeFederalSavingBanks.webp"
import IndoorVoice from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/IndoorVoice.webp"
import JustSettingUpMyTwittr from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/JustSettingUpMyTwittr.webp"
import LinesOfCode from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/LinesOfCode.webp"
import LittleProfessor from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/LittleProfessor.webp"
import MakingFaces from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/MakingFaces.webp"
import MathInterpreter from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/MathInterpreter.webp"
import MealTime from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/MealTime.webp"
import Numb3rs from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Numb3rs.webp"
import NutritionFacts from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/NutritionFacts.webp"
import OutDated from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/OutDated.webp"
import PizzaPy from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/LittleProfessor.webp"
import PlaybackSpeed from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/PlaybackSpeed.webp"
import ReRequestingAVanityPlate from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/ReRequestingAVanityPlate.webp"
import Refueling from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Refueling.webp"
import ResponseValidation from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/ResponseValidation.webp"
import Scourgify from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Scourgify.webp"
import SeasonsOfLove from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/SeasonsOfLove.webp"
import RegularUmExpression from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/RegularUmExpression.webp"
import TestingMyTwittr from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/TestingMyTwittr.webp"
import TipCalculator from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/TipCalculator.webp"
import VanityPlates from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/VanityPlates.webp"
import WatchOnYoutube from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/WatchOnYoutube.webp"
import Working9to5 from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Working9to5.webp"

const CS50python = [
    {
        "title":"Problem Set 0",
        "name":"Indoor Voice",
        "youtubelink":"https://www.youtube.com/embed/wa2RwtzOUjo?autoplay=1",
        "placeholderimage":IndoorVoice,
        "githublink":"https://github.com/Dors-Coding-School/Coding/blob/main/CS50%20Python/PSET0/indoor/indoor.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 0",
        "name":"Playback Speed",
        "youtubelink":"https://www.youtube.com/embed/8xx4m6dxsV8?autoplay=1",
        "placeholderimage":PlaybackSpeed,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/CS50%20Python/PSET0/playback/playback.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 0",
        "name":"Making Faces",
        "youtubelink":"https://www.youtube.com/embed/ceDspSheNdk?autoplay=1",
        "placeholderimage":MakingFaces,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/CS50%20Python/PSET0/faces/faces.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 0",
        "name":"Einstein",
        "youtubelink":"https://www.youtube.com/embed/cMf5iCtTaHA?autoplay=1",
        "placeholderimage":Einstein,
        "githublink":"https://github.com/Dors-Coding-School/Coding/blob/main/CS50%20Python/PSET0/einstein/einstein.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 0",
        "name":"Tip Calculator",
        "youtubelink":"https://www.youtube.com/embed/mXPGv3pW3Uk?autoplay=1",
        "placeholderimage":TipCalculator,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/CS50%20Python/PSET0/tip/tip.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 1",
        "name":"DeepThought",
        "youtubelink":"https://www.youtube.com/embed/-zEg7EuvpsQ?autoplay=1",
        "placeholderimage":DeepThought,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset1/deep?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 1",
        "name":"Home Federal Savings Bank",
        "youtubelink":"https://www.youtube.com/embed/k_8PuttQzuo?autoplay=1",
        "placeholderimage":HomeFederalSavingBanks,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset1/bank?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 1",
        "name":"File Extensions",
        "youtubelink":"https://www.youtube.com/embed/I-vJTQ00cYo?autoplay=1",
        "placeholderimage":FileExtension,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset1/extensions?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 1",
        "name":"Math Interpreter",
        "youtubelink":"https://www.youtube.com/embed/8S6XXuyW5WU?autoplay=1",
        "placeholderimage":MathInterpreter,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset1/interpreter?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 1",
        "name":"Meal Time",
        "youtubelink":"https://www.youtube.com/embed/ChuR8jNYXqs?autoplay=1",
        "placeholderimage":MealTime,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset1/meal?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 2",
        "name":"Camel Case",
        "youtubelink":"https://www.youtube.com/embed/GBGLrrf_MXg?autoplay=1",
        "placeholderimage":CamelCase,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/CS50%20Python/Pset2/camel/camel.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 2",
        "name":"Coke Machine",
        "youtubelink":"https://www.youtube.com/embed/l51ilHbRu9s?autoplay=1",
        "placeholderimage":CokeMachine,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/CS50%20Python/Pset2/coke/coke.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 2",
        "name":"Twttr",
        "youtubelink":"https://www.youtube.com/embed/Xc3eaIU9XGw?autoplay=1",
        "placeholderimage":JustSettingUpMyTwittr,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/CS50%20Python/Pset2/twttr/twttr.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 2",
        "name":"Vanity Plates",
        "youtubelink":"https://www.youtube.com/embed/Z46te4CmypM?autoplay=1",
        "placeholderimage":VanityPlates,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/CS50%20Python/Pset2/plates/plates.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 2",
        "name":"Nutrition Facts",
        "youtubelink":"https://www.youtube.com/embed/_1-UB9Nq7ic?autoplay=1",
        "placeholderimage":NutritionFacts,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/CS50%20Python/Pset2/nutrition/nutrition.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 3",
        "name":"Fuel Gauge",
        "youtubelink":"https://www.youtube.com/embed/xw_XVLOq2-Q?autoplay=1",
        "placeholderimage":FuelGauge,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/CS50%20Python/Pset3/fuel/fuel.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 3",
        "name":"Felipe's Taqueria",
        "youtubelink":"https://www.youtube.com/embed/g6J2JmuFt58?autoplay=1",
        "placeholderimage":FelipesTaqueria,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/CS50%20Python/Pset3/taqueria/taqueria.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 3",
        "name":"Grocery List",
        "youtubelink":"https://www.youtube.com/embed/5gBDup_u9cE?autoplay=1",
        "placeholderimage":GroceryList,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/CS50%20Python/Pset3/grocery/grocery.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 3",
        "name":"Out Dated",
        "youtubelink":"https://www.youtube.com/embed/B2fVYa4JQcw?autoplay=1",
        "placeholderimage":OutDated,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/CS50%20Python/Pset3/outdated/outdated.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 4",
        "name":"Emojize",
        "youtubelink":"https://www.youtube.com/embed/QCarXZrZT_g?autoplay=1",
        "placeholderimage":Emojize,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset4/emojize?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 4",
        "name":"Frank, Ian And Gleen's Letters",
        "youtubelink":"https://www.youtube.com/embed/sHVNfU8V7I4?autoplay=1",
        "placeholderimage":FrankIanAndGleensLetters,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset4/figlet?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 4",
        "name":"Adieu, Adieu",
        "youtubelink":"https://www.youtube.com/embed/dhghEt_vwDU?autoplay=1",
        "placeholderimage":AdieuAdieu,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset4/adieu?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 4",
        "name":"Guessing Game",
        "youtubelink":"https://www.youtube.com/embed/kKMvx5-VgcI?autoplay=1",
        "placeholderimage":GuessingGame,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset4/game?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 4",
        "name":"Little Professor",
        "youtubelink":"https://www.youtube.com/embed/N04JInRWW7s?autoplay=1",
        "placeholderimage":LittleProfessor,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset4/professor?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 4",
        "name":"Bitcoin Price Index",
        "youtubelink":"https://www.youtube.com/embed/l7R6NkXDsN4?autoplay=1",
        "placeholderimage":BitcoinPriceIndex,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset4/bitcoin?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 5",
        "name":"Testing My Twttr",
        "youtubelink":"https://www.youtube.com/embed/cMQQzH3jRkc?autoplay=1",
        "placeholderimage":TestingMyTwittr,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset5/test_twttr?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 5",
        "name":"Back To The Bank",
        "youtubelink":"https://www.youtube.com/embed/qiujjKmKnlQ?autoplay=1",
        "placeholderimage":BackToTheBank,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset5/test_bank?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 5",
        "name":"Re-Requesting a Vanity Plate",
        "youtubelink":"https://www.youtube.com/embed/GWHl5hTQEdY?autoplay=1",
        "placeholderimage":ReRequestingAVanityPlate,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset5/test_plates?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 5",
        "name":"Refueling",
        "youtubelink":"https://www.youtube.com/embed/S6CHuyxxk-I?autoplay=1",
        "placeholderimage":Refueling,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset5/test_fuel?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 6",
        "name":"Lines Of Code",
        "youtubelink":"https://www.youtube.com/embed/NEd3-fFs0zE?autoplay=1",
        "placeholderimage":LinesOfCode,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset6/lines?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 6",
        "name":"Pizza Py",
        "youtubelink":"https://www.youtube.com/embed/fa5i65zWJT4?autoplay=1",
        "placeholderimage":PizzaPy,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset6/pizza?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 6",
        "name":"Scourgify",
        "youtubelink":"https://www.youtube.com/embed/sxbYIfi1HDM?autoplay=1",
        "placeholderimage":Scourgify,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset6/scourgify?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 6",
        "name":"CS50 P-Shirt",
        "youtubelink":"https://www.youtube.com/embed/vWMme6zAL70?autoplay=1",
        "placeholderimage":CS50PShirt,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset6/shirt?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 7",
        "name":"Numb3rs",
        "youtubelink":"https://www.youtube.com/embed/Wm4_ekDWeWA?autoplay=1",
        "placeholderimage":Numb3rs,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset7/numb3rs?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 7",
        "name":"Watch On Youtube",
        "youtubelink":"https://www.youtube.com/embed/0cK_dHCfETU?autoplay=1",
        "placeholderimage":WatchOnYoutube,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset7/watch?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 7",
        "name":"Working 9 to 5",
        "youtubelink":"https://www.youtube.com/embed/_H3DMY2OL34?autoplay=1",
        "placeholderimage":Working9to5,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset7/working?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 7",
        "name":"Regular, Um, Expression",
        "youtubelink":"https://www.youtube.com/embed/midxaj8xiu0?autoplay=1",
        "placeholderimage":RegularUmExpression,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset7/um?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 7",
        "name":"Response Validation",
        "youtubelink":"https://www.youtube.com/embed/xbx3C6RhGmo?autoplay=1",
        "placeholderimage":ResponseValidation,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset7/response?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 8",
        "name":"Seasons of Love",
        "youtubelink":"https://www.youtube.com/embed/egC1dBNKSAg?autoplay=1",
        "placeholderimage":SeasonsOfLove,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset8/seasons?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 8",
        "name":"Cookie Jar",
        "youtubelink":"https://www.youtube.com/embed/dSgXaF7piD4?autoplay=1",
        "placeholderimage":CookieJar,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset8/jar?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Problem Set 8",
        "name":"CS50 Shirtificate",
        "youtubelink":"https://www.youtube.com/embed/s1FxAZkhTTA?autoplay=1",
        "placeholderimage":CS50Shirtificate,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/CS50%20Python/Pset8/shirtificate?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
    {
        "title":"Final Project",
        "name":"Final Project",
        "youtubelink":"https://www.youtube.com/embed/gYtyKP2MxBo?autoplay=1",
        "placeholderimage":FinalProject,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20Python/FinalProject?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=AxUjtIenvVdT"
    },
]

export default CS50python