import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import { useParams } from 'react-router-dom';
import './InnerCodeSolutions.css';
import TopMoldInner from '../../images/codeSolutionsTopMoldInner.svg';
import InnerCodeSolutionsCardLine from '../../components/CodeSolutions/InnerCodeSolutionsCardLine'
import CS50aiData from './InnerCardsData/CS50ai';
import CS50pythonData from './InnerCardsData/CS50python'
import CS50xData from './InnerCardsData/CS50x'
import CS50webData from './InnerCardsData/CS50web'
import CS50sqlData from './InnerCardsData/CS50sql'
import CS50cybersecurityData from './InnerCardsData/CS50cybersecurity'
import Web3Data from './InnerCardsData/WEB3'
import ResponsiveWebDesignData from './InnerCardsData/ResponsiveWebDesign'
import OdinProjectData from './InnerCardsData/OdinProject'
import SQLBoltData from './InnerCardsData/SQLBolt'
import CodingBatData from './InnerCardsData/CodingBat'

function InnerCodeSolutions() {

    const { course } = useParams();

    const courseDataMap = {
        'cs50ai': CS50aiData,
        'cs50python': CS50pythonData,
        'cs50x': CS50xData,
        'cs50web': CS50webData,
        'cs50sql': CS50sqlData,
        'cs50cybersecurity': CS50cybersecurityData,
        'web3': Web3Data,
        'responsivewebdesign': ResponsiveWebDesignData,
        'odinproject': OdinProjectData,
        'sqlbolt': SQLBoltData,
        'codingbat': CodingBatData,
    };

    const courseData = courseDataMap[course.toLowerCase()] || [];

    return (
        <div className="inner-code-solutions-screen">
            <NavBar />
            <div className='inner-code-solutions-top-mold'>
                <img src={TopMoldInner} alt="Top Mold" />
            </div>
            <div className="inner-code-solutions-text-container">
                <div className="inner-code-solutions-h1">
                    {course.toUpperCase()}
                </div>
                <div className="inner-code-solutions-h2">
                    Solutions
                </div>
            </div>

            {courseData.length > 0 ? (
                <div className="inner-code-solutions-lines">
                    {courseData.map((project, index) => (
                        <InnerCodeSolutionsCardLine project={project} key={index} isEven={index % 2 === 0}/>
                    ))}
                </div>
            ) : (
                <p>No course data available</p>
            )}
        </div>
    );
}

export default InnerCodeSolutions;
