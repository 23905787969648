import React from 'react'
import '../../../screens/AutomationsSalesPage/AutomationsSalesPage.css'
import Title from '../../../images/automationsSalesPage/Title.webp'

function AutomationsSalesPageHeader() {
    return (
        <div>
            {   
                <div className='automations-sales-page-header'>
                    <div className='automations-sales-page-header-line'></div>
                    <img src={Title} alt='' className='automations-sales-page-header-title' loading="lazy"></img>
                    <div className='automations-sales-page-header-text'>
                        <p>Are manual processes slowing down your growth? Imagine freeing up hours of your team’s time each week to focus on what truly matters—innovating and expanding your business.</p>
                    </div>
                </div>
            }
        </div>
    )
}
  
export default AutomationsSalesPageHeader