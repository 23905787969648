import React from 'react'
import NavBar from '../../components/NavBar/NavBar';
import './ThankYouPage.css'
import ThankYouPageDorsLogo from '../../images/dorsLogo.webp'
import ThankYouPageBackgroundLines from '../../images/landingPageBackgroundLines.webp'


function ThankYouBootcampPage() {
  return (
    <div>
      <NavBar />
      <div className='thank-you-page-screen'>
        <img className='thank-you-page-background-lines' alt='' src={ThankYouPageBackgroundLines}></img>
        <div className='thank-you-page-rectangle'>
            <img className='thank-you-page-dors-logo' alt='' src={ThankYouPageDorsLogo}></img>
            <div className='thank-you-page-first-paragraph'>
              <p>Thanks for Signing Up!</p>
              <p>Check your mailbox!</p>
            </div>
            <div className='thank-you-page-second-paragraph'>
              <p>We've just sent an email onboarding</p>
              <p>provinding more details about our</p>
              <p>Online Coding Bootcamp.</p>
            </div>
            <div className='thank-you-page-middle-line'></div>
            <div className='thank-you-page-third-paragraph'>
              <p>Watch the video below</p>
              <p>and join our Slack community!</p>
            </div>
            <div className='thank-you-page-video-container'>
              <iframe className="thank-you-page-quiz-video" src='https://www.youtube.com/embed/4gG0OmmSvxM?autoplay=1' title="Bootcamp Video" frameborder="0" allow="accelerometer" allowfullscreen></iframe>
            </div>
            <a href="https://join.slack.com/t/codingbootcam-lpp5182/shared_invite/zt-2e519t1k0-FTTFCnjlqZV83vRP_TWqiA"><button 
              className='thank-you-page-quiz-button'
            >
              Join Slack!
            </button></a>
        </div>
      </div>
    </div>
  )
}

export default ThankYouBootcampPage