import React from 'react'
import NavBar from '../../components/NavBar/NavBar'
import './Contribute.css'

function ContributeScreen() {
  return (
    <div className='screen-container-contribute'>
        <NavBar />
        <div className='contribute-screen'>
            <h1 className="h1-contribute-page">Contribute with Us</h1>
            
            <h2 className="h2-contribute-page">
                You've absolutely aced our coding curriculum and now it's time to take that leap from theory to making real-world impact with CodingDors, the platform that has been with you from your first "Hello, World!" to this pivotal moment.
            </h2>

            <br/>
            <h2 className='h2-contribute-page'>
                As a new contributor to the open-source heartbeat of CodingDors, you’re about to:
            </h2>

            <div className='cons-contribute'>
                <div className='cons-card-contribute'>
                    <h2>Experience Agile in Action</h2>
                    <h3>Learn how sprint cycles drive the continuous evolution of CodingDors.</h3>
                </div>
            
                <div className='cons-card-contribute'>
                    <h2>Git Going Strong</h2>
                    <h3>Apply your Git knowledge to a living project—merge, stash, and reset with purpose.</h3>
                </div>

                <div className='cons-card-contribute'>
                    <h2>Deploy with Confidence</h2>
                    <h3>Take your code to the cloud as you deploy features that elevate CodingDors.</h3>
                </div>

                <div className='cons-card-contribute'>
                    <h2>Streamline with GitHub Actions</h2>
                    <h3>Set up CI/CD workflows that ensure CodingDors operates like a well-oiled machine.</h3>
                </div>

                <div className='cons-card-contribute'>
                    <h2>Debug with Determination</h2>
                    <h3>Troubleshoot and refine CodingDors to enhance user experience.</h3>
                </div>
            </div>

            <br/>
            <h2 className='h2-contribute-page'>
                And here's something invaluable:
            </h2>

            <br/>
            <div className='cons-card-contribute-ultimate'>
                <h2>Peer Testimonials for Your Career</h2>
                <h3>Collect firsthand accounts and testimonials from fellow coders that highlight your contributions to CodingDors, giving you a solid reputation boost in the job market.</h3>
            </div>
           

            <br/>
            <h2 className='h2-contribute-page'>
                We understand everyone's schedule is unique, so you call the shots on your time commitment. However, investing at least 5 hours per week over the next month is our recommendation for the best learning and contributing experience. This consistent effort can unlock your potential to make significant contributions and help you get the most out of the mentorship from our dev team.
            </h2>

            <br/>
            <h2 className='h2-contribute-page'>
                Ready to join the ranks and make your mark? Click the button below to arrange your first strategy meeting for CodingDors and kickstart your journey of professional growth and opportunity.
            </h2>

            <a href="https://www.dorscodingschool.com/contribute-with-us" target='_blank' rel="noreferrer"><button class="btn-contribute-page-yellow">Schedule Meeting</button></a>
        
            <br/>
            <h2 className='h2-contribute-page'>
                Can’t wait to get coding with you!
            </h2>
        </div>
    </div>
  )
}

export default ContributeScreen