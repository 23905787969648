/* Creates the csrf_token needed in Django forms */
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const AuthService = {
    login: async (email, password) => {
        const formData = new URLSearchParams();
        formData.append('email', email);
        formData.append('password', password);
    
        const response = await fetch('/custom_login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: formData
        });
        const data = await response.json()
        
        if (response.ok === true) {
            // Store user data in local storage
            localStorage.setItem('user', JSON.stringify(email));
            return email;
        } else {
            return data.error
        }
    }
    ,
    register: async (username, password1, password2, email) => {
        const formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password1', password1); // Note: Django allauth expects 'password1' and 'password2'
        formData.append('password2', password2); // Normally you would have two fields in your form to confirm the password
        formData.append('email', email);

        const response = await fetch('/custom_signup/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: formData
        });

        const data = await response.json()
        if (data.ok) {
            localStorage.setItem('user', JSON.stringify(email));
            return email;
        } else {
            return data.error
        }
    },
    logout: async () => {
        localStorage.removeItem('user');
    },
    
};

export default AuthService;