import React, { useState } from 'react';
import SolutionModal from './SolutionModal/SolutionModal';

function SolutionButton({ problem_id, title }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    return (
        <div>
            <button className='btn-show-solution' onClick={openModal}>Show Solution</button>
            <SolutionModal isOpen={modalIsOpen} closeModal={closeModal} problem_id={problem_id} title={title} />
        </div>
  )
}

export default SolutionButton