import React, { useState } from 'react'
import thumbPlaceholder from '../../images/homepage/wiki.webp'

function VideoCard3() {
    const [videoLoaded, setVideoLoaded] = useState(false);

    const handleVideoLoad = () => {
        setVideoLoaded(true);
    };

    return (
        <div className="homepage-video-card" onClick={handleVideoLoad}>
            {!videoLoaded ? (
                <>
                    <img
                        loading="lazy"
                        src={thumbPlaceholder}
                        alt="Video Placeholder"
                        className="homepage-video-placeholder"                               
                    />
                </>
            ) : (
                <iframe className='homepage-video' src="https://www.youtube.com/embed/dzxROgoafOs" title="(CS50 WEB) WIKI - PROJECT 1 | SOLUTION" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            )}
            <div className='homepage-comments-div'>
                <div className='homepage-comment-container'>
                    <div className="homepage-comment-container-left">E</div>
                    <div className='homepage-comment-container-right'>
                        <h5>@ericzheng4815 <span>1 year ago</span></h5>
                        <h6>The best tutorial on YouTube. Period.</h6>
                        <p>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.28766 2.06246C7.28766 1.37496 6.60841 0.916626 6.07766 0.916626C5.70825 0.916626 5.67937 1.19713 5.62254 1.75079C5.59733 1.99371 5.56708 2.28888 5.50016 2.63538C5.32325 3.55296 4.71183 4.72538 4.127 5.076V7.79163C4.12516 8.82288 4.46891 9.16663 5.9585 9.16663H7.68779C8.68512 9.16663 8.92666 8.50983 9.0165 8.26646L9.02245 8.24996C9.0747 8.10971 9.18654 7.99925 9.31487 7.87413C9.45695 7.73388 9.6192 7.57483 9.73975 7.33329C9.88229 7.04775 9.8635 6.79383 9.84654 6.56788C9.836 6.43083 9.82637 6.30433 9.85433 6.18746C9.88366 6.06371 9.92125 5.96975 9.95745 5.87992C10.023 5.71675 10.0835 5.5655 10.0835 5.27079C10.0835 4.58329 9.74066 4.12588 9.02245 4.12588H7.10433C7.10433 4.12588 7.28766 2.74996 7.28766 2.06246ZM2.521 4.58329C2.33866 4.58329 2.16379 4.65573 2.03486 4.78466C1.90593 4.91359 1.8335 5.08846 1.8335 5.27079V8.47913C1.8335 8.66146 1.90593 8.83633 2.03486 8.96526C2.16379 9.09419 2.33866 9.16663 2.521 9.16663C2.70333 9.16663 2.8782 9.09419 3.00713 8.96526C3.13606 8.83633 3.2085 8.66146 3.2085 8.47913V5.27079C3.2085 5.08846 3.13606 4.91359 3.00713 4.78466C2.8782 4.65573 2.70333 4.58329 2.521 4.58329Z" fill="white"/></svg>
                            11
                        </p>
                    </div>
                </div>
                <div className='homepage-comment-container'>
                    <div className="homepage-comment-container-left">T</div>
                    <div className='homepage-comment-container-right'>
                        <h5>@TheCsTutor-qk3uy <span>3 months ago</span></h5>
                        <h6>amazing work!</h6>
                        <p>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.28766 2.06246C7.28766 1.37496 6.60841 0.916626 6.07766 0.916626C5.70825 0.916626 5.67937 1.19713 5.62254 1.75079C5.59733 1.99371 5.56708 2.28888 5.50016 2.63538C5.32325 3.55296 4.71183 4.72538 4.127 5.076V7.79163C4.12516 8.82288 4.46891 9.16663 5.9585 9.16663H7.68779C8.68512 9.16663 8.92666 8.50983 9.0165 8.26646L9.02245 8.24996C9.0747 8.10971 9.18654 7.99925 9.31487 7.87413C9.45695 7.73388 9.6192 7.57483 9.73975 7.33329C9.88229 7.04775 9.8635 6.79383 9.84654 6.56788C9.836 6.43083 9.82637 6.30433 9.85433 6.18746C9.88366 6.06371 9.92125 5.96975 9.95745 5.87992C10.023 5.71675 10.0835 5.5655 10.0835 5.27079C10.0835 4.58329 9.74066 4.12588 9.02245 4.12588H7.10433C7.10433 4.12588 7.28766 2.74996 7.28766 2.06246ZM2.521 4.58329C2.33866 4.58329 2.16379 4.65573 2.03486 4.78466C1.90593 4.91359 1.8335 5.08846 1.8335 5.27079V8.47913C1.8335 8.66146 1.90593 8.83633 2.03486 8.96526C2.16379 9.09419 2.33866 9.16663 2.521 9.16663C2.70333 9.16663 2.8782 9.09419 3.00713 8.96526C3.13606 8.83633 3.2085 8.66146 3.2085 8.47913V5.27079C3.2085 5.08846 3.13606 4.91359 3.00713 4.78466C2.8782 4.65573 2.70333 4.58329 2.521 4.58329Z" fill="white"/></svg>
                            25
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoCard3