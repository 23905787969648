import React from 'react'

function BootcampContent() {
  return (
    <div className='bootcamp-black-div'>
        <div className='bootcamp-price-row'>
            <div className='bootcamp-price-row-left'>
                <h2>3-Month Program</h2>
                <h2>3 Hours Per Week</h2> 
                <span>(Tue & Thu at 6pm CET) OR (Tue & Thu at 6pm PST)</span>
            </div>
            <div className='bootcamp-price-row-right'>
                <h1>$1000 <span>/total</span></h1>
            </div>
        </div>
        <p>
            📚 <strong>Unlock Your Potential in AI and Python Programming!</strong>
        </p>
        <p className='bootcamp-p-right'>
            💡 Are you ready to dive into the world of Artificial Intelligence and Python programming? Our AI Bootcamp is designed to provide a <strong>strong foundation</strong> in these cutting-edge fields. 
        </p>
        <p>
            🚀 Whether you’re a beginner or looking to enhance your skills, our comprehensive curriculum, based on the <strong>renowned CS50 courses</strong>, will equip you with the knowledge and confidence to excel.
        </p>
    </div>
  )
}

export default BootcampContent