import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// Set up Sentry
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://cc38c2cc299d4fadad7fedab4a74285f@o4505371497463808.ingest.sentry.io/4505371506245632",
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [new Sentry.Replay()],
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV || 'development',

  beforeSend(event) {
    // Check if the error event is from localhost
    if (event.request && event.request.url && event.request.url.includes("localhost")) {
      return null; // Discard events from localhost
    }
    return event; // Send other events to Sentry
  }
});

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: 'df17af68-e9cd-4a39-8fb8-38ef1fd2b397',
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView(); // Tracks an initial page view


const root = ReactDOM.createRoot(document.getElementById('root'));
//rook.start({ token: '116b3626aa092dd58aa5ce953f17c3ab8e18e929db332c379810597bb864881e' });
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
