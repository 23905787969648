import React from 'react'

function ButtonExercise({ id, status }) {

    function goToExercise() {
      window.location.href = `/problem/${id}`; // navigate to next exercise
    }
  
    return (
      <div>
        <button 
          className='btn-previous-next-exercise' 
          onClick={goToExercise}
        >{status}
        </button>
      </div>
    );
}

export default ButtonExercise