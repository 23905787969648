import SurveyForm from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/SurveyForm.webp"
import TributePage from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/TributePage.webp"
import TechnicalDocumentation from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/TechnicalDocumentation.webp"
import PersonalPortfolioWebpage from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/PersonalPortfolioWebpage.webp"
import ProductLandingPage from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/ProductLandingPage.webp"

const ResposiveWebDesign = [
    {
        "title":"FreeCodeCamp",
        "name":"Survey Form",
        "youtubelink":"https://www.youtube.com/embed/qehhWRUZaxY?autoplay=1",
        "placeholderimage":SurveyForm,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/freeCodeCamp/Responsive%20Web%20Design/Survey%20Form?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=TXxzt1b3QgZa"
    },
    {
        "title":"FreeCodeCamp",
        "name":"Tribute Page",
        "youtubelink":"https://www.youtube.com/embed/gNKSnDr6aRQ?autoplay=1",
        "placeholderimage":TributePage,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/freeCodeCamp/Responsive%20Web%20Design/Tribute%20Page?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=TXxzt1b3QgZa"
    },
    {
        "title":"FreeCodeCamp",
        "name":"Technical Documentation Page",
        "youtubelink":"https://www.youtube.com/embed/7StobNYRNuA?autoplay=1",
        "placeholderimage":TechnicalDocumentation,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/freeCodeCamp/Responsive%20Web%20Design/Technical%20Documentation%20Page?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=TXxzt1b3QgZa"
    },
    {
        "title":"FreeCodeCamp",
        "name":"Personal Portfolio Webpage",
        "youtubelink":"https://www.youtube.com/embed/dlxN-Nz_Xkg?autoplay=1",
        "placeholderimage":PersonalPortfolioWebpage,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/freeCodeCamp/Responsive%20Web%20Design/Personal%20Portfolio%20Webpage"
    },
    {
        "title":"FreeCodeCamp",
        "name":"Product Landing Page",
        "youtubelink":"https://www.youtube.com/embed/njnDSiyhFdk?autoplay=1",
        "placeholderimage":ProductLandingPage,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/freeCodeCamp/Responsive%20Web%20Design/Product%20Landing%20Page"
    }
]

export default ResposiveWebDesign