import React from 'react'
import privateCardImage from '../../images/Tree/privateCardTree.webp'
import scheduleMeetingLogo from '../../images/scheduleMeetingLogo.png'
import {trackMixpanel } from '../../mixpanelWrapper';

function CardPrivate() {
  const handleClick = () => {
    trackMixpanel('ClickBootcampSidebar');
  };

  return (
    <div className='main-screen-right-border' style={{ 'zIndex': -1 }}>
        <div className='join-discord-div'>
            <h6 className='join-discord-community'>Ready to learn <span className='join-discord-community-gray'>Code</span></h6>
            <p className='join-discord-community-p'>with our <span className='join-discord-community-gray-p'>1-on-1 Tutoring?</span></p>
        </div>
        <img src={privateCardImage} alt='Tutoring' className='main-img-private' />
        <a href="/coachingplans" target='_blank' rel="noreferrer">
            <button className="btn-join-discord" onClick={handleClick}>
                <img src={scheduleMeetingLogo} alt='' style={{ width: '18px', marginRight: '3px' }} />
                Private Tutoring   
            </button>
        </a>
    </div>
  )
}

export default CardPrivate