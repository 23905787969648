import React, {useState } from "react"
import './InnerCodeSolutionsCardLine.css'
import GitHubIcon from '../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/GitHubIcon.svg'

function InnerCodeSolutionsCardLine({ project, isEven }) {

    const rowClass = isEven
        ? "inner-code-solutions-card-line-row inner-code-solutions-card-line-row-even"
        : "inner-code-solutions-card-line-row inner-code-solutions-card-line-row-odd";

    const [videoLoaded, setVideoLoaded] = useState(false);

    const handleVideoLoad = () => {
        setVideoLoaded(true);
    };

    return (
        <div className="inner-code-solutions-card-line-container">
            <div className={rowClass}>
                <div className="inner-code-solutions-card-line-row-video" onClick={handleVideoLoad}>
                    {!videoLoaded ? (
                    <>
                        <img
                            loading="lazy"
                            src={project.placeholderimage}
                            alt="Video Placeholder"
                            className="inner-code-solutions-card-line-row-video-placeholder"                               
                        />
                        <div
                            style={{
                                position: 'absolute',
                                color: 'white',
                                fontSize: '70px',
                                pointerEvents: 'none' // Ensures that the click event is passed through to the image below
                            }}
                        >
                        ▶
                        </div>
                    </>
                    ) : (
                        <iframe loading="lazy" src={project.youtubelink} className='inner-code-solutions-card-line-row-youtube-video' title='course' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    )}
                </div>
                <div className="inner-code-solutions-card-line-row-name">
                    <p>{project.title}</p>
                    <p>{project.name}</p>
                </div>
                <div className="inner-code-solutions-card-line-row-button-container" onClick={() => window.open(project.githublink, '_blank')}>
                    <p>Get The Solution!</p>
                    <img src={GitHubIcon} alt='GitHubIcon'></img>
                </div>
            </div>
        </div>
    )
}

export default InnerCodeSolutionsCardLine

