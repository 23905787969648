import React, { useState } from 'react'
import thumbPlaceholder from '../../images/bootcamp/bootcampAI.webp'

function BootcampAIVideo() {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleVideoLoad = () => {
      setVideoLoaded(true);
  };

  return (
    <div className='bootcamp-video-div'>
        <h1>How The Bootcamp <span>Works</span></h1>
        <h6>This video outlines the advantages you'll gain by studying coding with us.</h6>
        <div className="bootcamp-video-container" onClick={handleVideoLoad}>
                {!videoLoaded ? (
                    <>
                        <img
                            loading="lazy"
                            src={thumbPlaceholder}
                            alt="Video Placeholder"
                            className="bootcamp-video-placeholder"                               
                        />
                        <div 
                            loading="lazy"
                            style={{
                                position: 'absolute',
                                color: 'white',
                                fontSize: '60px',
                                pointerEvents: 'none' // Ensures that the click event is passed through to the image below
                            }}
                        >
                        ▶
                        </div>
                    </>
                ) : (
                    <iframe src="https://www.youtube.com/embed/M82pmC9J3fk?autoplay=1" title="Bootcamp-Video" className="bootcamp-video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                )}
            </div>
    </div>
  )
}

export default BootcampAIVideo