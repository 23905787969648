import React from 'react'
import '../../../screens/AutomationsSalesPage/AutomationsSalesPage.css'
import Header2Image from '../../../images/automationsSalesPage/Header2Image.webp'

function AutomationsSalesPageHeader2() {
    return (
        <div>
            {   
                <div className='automations-sales-page-header'>
                    <div className='automations-sales-page-header2-part4'>
                        <div className='automations-sales-page-header2-part4-column'>
                            <p>Don’t let outdated processes <strong>hold you back.</strong> Book a <strong>free consultation call</strong> with our automation experts today, and receive your complimentary <strong>flow diagram and automation plan.</strong></p>
                        </div>
                        <div className='automations-sales-page-header2-part4-column2'>
                            <p>👉 Schedule Your Free Call Now</p>
                            <a href='/schedulemeeting-automations' target="_blank" rel="noopener noreferrer">
                                <button type="button">Schedule</button>
                            </a>
                            
                        </div>
                    </div>
                    <div className='automations-sales-page-header2-line'>
                        <div className='automations-sales-page-header2-line-block1'>
                            <h1><strong>UNLOCK EFFICIENCY</strong></h1>
                            <h1>and</h1>
                            <h1><strong>DRIVE PRODUCTIVITY.</strong></h1>
                        </div>
                        <div className='automations-sales-page-header2-line-block2'>
                            <img src={Header2Image} alt='' loading="lazy"></img>
                        </div>
                    </div>
                    <div className='automations-sales-page-header2-line2'>
                        <div className='automations-sales-page-header2-line2-image1'>
                            <p>Our journey begins by <strong>mapping your business processes</strong> with a detailed <strong>flowchart diagram.</strong> During our <strong>free</strong> consultation, we’ll provide this <strong>personalized diagram</strong> and show you how to automate your operations <strong>effectively.</strong></p>
                        </div>
                        <div></div>
                    </div>
                    <div className='automations-sales-page-header2-line3'>
                        <div className='automations-sales-page-header2-line2-image2'>
                            <p>Leveraging n8n, one of the most cutting-edge automation tools available, we’ll <strong>automate these processes</strong> to make your business <strong>run smoother and better</strong> than ever before.</p>
                        </div>
                        <div></div>
                    </div>
                    <div className='automations-sales-page-header2-part2'>
                        <p>Why Choose Our Automation Services?</p>
                    </div>
                    <div className='automations-sales-page-header2-part2-cards'>
                        <div className='automations-sales-page-header2-card1'>
                            <div className='automations-sales-page-header2-card-img'></div>
                            <div className='automations-sales-page-header2-card-text'>
                                <p><strong>•	Free Personalized Flow Diagram</strong> Visualize your business processes and identify inefficiencies.</p>
                            </div>
                        </div>
                        <div className='automations-sales-page-header2-card1'>
                            <div className='automations-sales-page-header2-card-img2'></div>
                            <div className='automations-sales-page-header2-card-text'>
                                <p><strong>•	Advanced Automation with n8n</strong> Harness the power of one of the most innovative tools in automation.</p>
                            </div>
                        </div>
                        <div className='automations-sales-page-header2-card1'>
                            <div className='automations-sales-page-header2-card-img3'></div>
                            <div className='automations-sales-page-header2-card-text'>
                                <p><strong>•	Expert Support</strong> Our team guides you every step of the way.</p>
                            </div>
                        </div>
                        <div className='automations-sales-page-header2-card1'>
                            <div className='automations-sales-page-header2-card-img4'></div>
                            <div className='automations-sales-page-header2-card-text'>
                                <p><strong>•	Proven Results</strong> Clients have seen up to a 50% increase in efficiency.</p>
                            </div>
                        </div>
                    </div>
                    <div className='automations-sales-page-header2-part3'>
                        <p>Ready to Elevate Your Business?</p>
                    </div>
                    <div className='automations-sales-page-header2-part4'>
                        <div className='automations-sales-page-header2-part4-column'>
                            <p>Don’t let outdated processes <strong>hold you back.</strong> Book a <strong>free consultation call</strong> with our automation experts today, and receive your complimentary <strong>flow diagram and automation plan.</strong></p>
                        </div>
                        <div className='automations-sales-page-header2-part4-column2'>
                            <p>👉 Schedule Your Free Call Now</p>
                            <a href='/schedulemeeting-automations' target="_blank" rel="noopener noreferrer">
                                <button type="button">Schedule</button>
                            </a>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
  
export default AutomationsSalesPageHeader2