import EtchASketch from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/EtchASketch.webp"
import LandigPageOdin from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/LandigPageOdin.webp"
import Recipes from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Recipes.webp"
import Calculator from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Calculator.webp"
import RockPaperScissors from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/RockPaperScissors.webp"
import SignUpForm from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/SignUpForm.webp"
import AdminDashboard from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/AdminDashboard.webp"

const OdinProject = [
    {
        "title":"OdinProject",
        "name":"Recipes",
        "youtubelink":"https://www.youtube.com/embed/51p1emN1kp0?autoplay=1",
        "placeholderimage":Recipes,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/The%20Odin%20Project/recipes?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=5jFyzhK17qCp"
    },
    {
        "title":"OdinProject",
        "name":"Landing Page",
        "youtubelink":"https://www.youtube.com/embed/6zVJWDJIsgY?autoplay=1",
        "placeholderimage":LandigPageOdin,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/The%20Odin%20Project/landing%20page?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=5jFyzhK17qCp"
    },
    {
        "title":"OdinProject",
        "name":"Rock Paper Scissors",
        "youtubelink":"https://www.youtube.com/embed/shZuBowaYbk?autoplay=1",
        "placeholderimage":RockPaperScissors,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/The%20Odin%20Project/rock%20paper%20scissors?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=5jFyzhK17qCp"
    },
    {
        "title":"OdinProject",
        "name":"Etch-A-Sketch",
        "youtubelink":"https://www.youtube.com/embed/0YjwdFT-O9Y?autoplay=1",
        "placeholderimage":EtchASketch,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/The%20Odin%20Project/etch-a-sketch?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=5jFyzhK17qCp"
    },
    {
        "title":"OdinProject",
        "name":"Calculator",
        "youtubelink":"https://www.youtube.com/embed/Kyxvms8pnSU?autoplay=1",
        "placeholderimage":Calculator,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/The%20Odin%20Project/calculator?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=5jFyzhK17qCp"
    },
    {
        "title":"OdinProject",
        "name":"Sign Up Form",
        "youtubelink":"https://www.youtube.com/embed/sPME3TTVi3A?autoplay=1",
        "placeholderimage":SignUpForm,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/The%20Odin%20Project/sign%20up%20form?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=5jFyzhK17qCp"
    },
    {
        "title":"OdinProject",
        "name":"Admin Dashboard",
        "youtubelink":"https://www.youtube.com/embed/Y4s03TSMDgs?autoplay=1",
        "placeholderimage":AdminDashboard,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/The%20Odin%20Project/admin%20dashboard?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=5jFyzhK17qCp"
    }
]

export default OdinProject