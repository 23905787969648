import VideoGameMarketplace from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/VideoGameMarketplace.webp"
import FundraisingSmartContract from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/FundraisingSmartContract.webp"
import PeerToPeerNetwork from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/PeerToPeerNetwork.webp"
import WEB3ClientSidePackage from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/WEB3ClientSidePackage.webp"
import SmartContractInRust from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/SmartContractInRust.webp"

const WEB3 = [
    {
        "title":"BlockChain",
        "name":"Build a Video Game Marketplace Blockchain",
        "youtubelink":"https://www.youtube.com/embed/CMjhlicuk0I?autoplay=1",
        "placeholderimage":VideoGameMarketplace,
        "githublink":"https://github.com/Dors-Coding-School/Web3/tree/main/build-a-video-game-marketplace-blockchain?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=JvtmgHOoGza4"
    },
    {
        "title":"BlockChain",
        "name":"Build a Fundraising Smart Contract",
        "youtubelink":"https://www.youtube.com/embed/DMoNEEHYQEc?autoplay=1",
        "placeholderimage":FundraisingSmartContract,
        "githublink":"https://github.com/Dors-Coding-School/Web3/tree/main/build-a-fundraising-smart-contract?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=JvtmgHOoGza4"
    },
    {
        "title":"BlockChain",
        "name":"Build a Peer-To-Peer Network",
        "youtubelink":"https://www.youtube.com/embed/V371eHC5O4g?autoplay=1",
        "placeholderimage":PeerToPeerNetwork,
        "githublink":"https://github.com/Dors-Coding-School/Web3/tree/main/build-a-peer-to-peer-network?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=JvtmgHOoGza4"
    },
    {
        "title":"BlockChain",
        "name":"Build a WEB3 Client-Side Package",
        "youtubelink":"https://www.youtube.com/embed/PZMUY-I-4BE?autoplay=1",
        "placeholderimage":WEB3ClientSidePackage,
        "githublink":"https://github.com/Dors-Coding-School/Web3/tree/main/build-a-web3-client-side-package-for-your-dapp?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=JvtmgHOoGza4"
    },
    {
        "title":"BlockChain",
        "name":"Build a Fundraising Smart Contract",
        "youtubelink":"https://www.youtube.com/embed/imgp_7b2EOs?autoplay=1",
        "placeholderimage":SmartContractInRust,
        "githublink":"https://github.com/Dors-Coding-School/Web3/tree/main/build-a-peer-to-peer-network?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=JvtmgHOoGza4"
    }
]

export default WEB3