import React, { useEffect, useState, useCallback } from 'react';
import './TheoryModal.css'
import Modal from 'react-modal';
import Spinner from 'react-bootstrap/Spinner';

// Make sure to bind modal to your app element
Modal.setAppElement('#root');

const MyModal = (props) => {

    const [explanation, setExplanation] = useState('')
    const [title, setTitle] = useState('')
    const [loadingTheory, setLoadingTheory] = useState(false)
    const [isTheory, setIsTheory] = useState(true)

    const getTheory = useCallback(async () => {
        setIsTheory(true)
        setLoadingTheory(true)
        
        const formData = new FormData();
        formData.append('id', props.problem_id);
    
        // Sending a POST request to the server with the form data
        const response = await fetch('/get_theory/', {
            method: 'POST',
            body: formData
        });
    
        // Parsing the response data as JSON
        const data = await response.json();
        setExplanation(data.explanation)
        setTitle(data.title)
        setLoadingTheory(false)
    }, [props.problem_id]); // add the dependencies here
    
    useEffect(() => {
        if(props.isOpen){
            getTheory()
        } 
    }, [props.isOpen, getTheory]);    

    const getHint = async () => {
        setIsTheory(false)
        setLoadingTheory(true)
        
        const formData = new FormData();
        formData.append('id', props.problem_id);

        // Sending a POST request to the server with the form data
        const response = await fetch('/get_hint/', {
            method: 'POST',
            body: formData
        });
    
        // Parsing the response data as JSON
        const data = await response.json();
        setExplanation(data.explanation)
        setLoadingTheory(false)
    };

    const addBackGroundToCodeBlocks = (explanation) => {
        // Split the text into an array of code blocks based on triple backticks
        const codeBlocks = explanation.split('```')
    
        // Map over the array of code blocks and replace the text within the backticks with a version wrapped in a span tag
        const highlightedCodeBlocks = codeBlocks.map((block, index) => {
            if (index % 2 !== 0) {
                // This is a code block enclosed by triple backticks, so wrap the text in a div tag with a class for styling
                const withoutPython = block.replace(/python/g, ""); // Remove "python" from the block
                return <div className="code-block-theory">{withoutPython}</div>;
            } else {
                // This is not a code block enclosed by triple backticks
                // Split it further based on single backtick
                const inlineCodeBlocks = block.split('`')
                return inlineCodeBlocks.map((inlineBlock, inlineIndex) => {
                    if (inlineIndex % 2 !== 0) {
                        // This is a code block enclosed by single backticks, so wrap it in a span tag with a class for styling
                        return <div className="inline-code-block-theory">{inlineBlock}</div>;
                    } else {
                        // This is not a code block, so just return the original text
                        return inlineBlock
                    }
                })
            }
        })
    
        // Return the array of code blocks with the wrapped span tags
        return highlightedCodeBlocks
    }
    

    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.closeModal}
            contentLabel="My Modal"
            className="modal"
            overlayClassName="modal-overlay"
        >
            {loadingTheory ? (
                <div className="spinner-container" style={{ width: '100%', height: '100%'}}>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : 
                <div className='modal-content'>
                    <div className='div-close-btn-modal'>
                        {isTheory ? 
                            <button className='btn-modal-change-theory' onClick={getHint}>Hints</button>
                        :
                            <button className='btn-modal-change-theory' onClick={getTheory}>Theory</button>
                        }
                        <button onClick={props.closeModal} className='close-btn-modal'>X</button>
                    </div>

                    <div className='modal-title'>
                        <h2>{isTheory ? 'Theory' : 'Hint'}<span style={{ fontStyle: 'italic', marginLeft: '10px', color: 'rgba(98, 98, 98, 1)' }}>{title}</span></h2>                        
                        {explanation && <p style={{ whiteSpace: "pre-wrap" }} className='p-modal'>{addBackGroundToCodeBlocks(explanation)}</p>}
                    </div>
                    <div className='div-btn-modal'>
                        <button onClick={props.closeModal} className='btn-modal-theory'>Go Back to Problem</button>
                    </div>
                </div>
            }
        </Modal>
    );
};

export default MyModal;
