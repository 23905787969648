import Readability from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Readability.webp"
import Runoff from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Runoff.webp"
import Recover from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Recover.webp"
import Finance from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Finance.webp"
import Scrabble from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Scrabble.webp"
import FinalProjectx from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/FinalProjectx.webp"
import Homepage from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Homepage.webp"
import Caesar from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Caesar.webp"
import Plurality from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Plurality.webp"
import Fiftyville from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Fiftyville.webp"
import Cash from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Cash.webp"
import Mario from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Mario.webp"
import Speller from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Speller.webp"
import Substitution from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Substitution.webp"
import Sort from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Sort.webp"
import Readability2 from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Readability2.webp"
import Dna from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Dna.webp"
import Filter from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Filter.webp"
import Movies from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Movies.webp"
import Songs from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Songs.webp"
import Volume from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Volume.webp"
import Mariox from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Mariox.webp"
import Scratch from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Scratch.webp"
import WorldCup from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/WorldCup.webp"
import PopulationGrowth from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/PopulationGrowth.webp"
import Credit from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Credit.webp"
import Trivia from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Trivia.webp"
import Credit2 from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Credit2.webp"
import Inheritance from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Inheritance.webp"
import Birthday from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Birthday.webp"
import Tideman from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Tideman.webp"

const CS50x = [
    {
        "title":"Problem Set 0",
        "name":"Scratch",
        "youtubelink":"https://www.youtube.com/embed/vYlS9mgrdSc?autoplay=1",
        "placeholderimage":Scratch,
        "githublink":"https://scratch.mit.edu/projects/337824419?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Lab 1",
        "name":"Population Growth",
        "youtubelink":"https://www.youtube.com/embed/W6q4X2rxxos?autoplay=1",
        "placeholderimage":PopulationGrowth,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Labs/lab1.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 1",
        "name":"Mario",
        "youtubelink":"https://www.youtube.com/embed/vl7uhVQ-_Ug?autoplay=1",
        "placeholderimage":Mariox,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset1/mario.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 1",
        "name":"Cash",
        "youtubelink":"https://www.youtube.com/embed/jgqRPPzHKn0?autoplay=1",
        "placeholderimage":Cash,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset1/cash1.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 1",
        "name":"Credit",
        "youtubelink":"https://www.youtube.com/embed/xuxouATNfBs?autoplay=1",
        "placeholderimage":Credit2,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset1/credit.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Lab 2",
        "name":"Scrabble",
        "youtubelink":"https://www.youtube.com/embed/ACpJaMTLMbI?autoplay=1",
        "placeholderimage":Scrabble,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Labs/lab2.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 2",
        "name":"Readability",
        "youtubelink":"https://www.youtube.com/embed/_jX_FUWWNSo?autoplay=1",
        "placeholderimage":Readability,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset2/readability.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 2",
        "name":"Caesar",
        "youtubelink":"https://www.youtube.com/embed/c_sQKoAhvf8?autoplay=1",
        "placeholderimage":Caesar,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset2/caesar.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 2",
        "name":"Substitution",
        "youtubelink":"https://www.youtube.com/embed/KwF8-7Ng8Wo?autoplay=1",
        "placeholderimage":Substitution,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset2/substitution.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Lab 3",
        "name":"Sort",
        "youtubelink":"https://www.youtube.com/embed/lUs-iU3PWcU?autoplay=1",
        "placeholderimage":Sort,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Labs/lab3.txt?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 3",
        "name":"Plurality",
        "youtubelink":"https://www.youtube.com/embed/c4TXlWiygUo?autoplay=1",
        "placeholderimage":Plurality,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset3/plurality.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 3",
        "name":"Runoff",
        "youtubelink":"https://www.youtube.com/embed/XBHoD6IbSms?autoplay=1",
        "placeholderimage":Runoff,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset3/runoff.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 3",
        "name":"Tideman",
        "youtubelink":"https://www.youtube.com/embed/I8RAULhM10g?autoplay=1",
        "placeholderimage":Tideman,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset3/tideman.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Lab 4",
        "name":"Volume",
        "youtubelink":"https://www.youtube.com/embed/VJqATzJ-1TM?autoplay=1",
        "placeholderimage":Volume,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Labs/lab4.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 4",
        "name":"Filter",
        "youtubelink":"https://www.youtube.com/embed/r_5URERpuvQ?autoplay=1",
        "placeholderimage":Filter,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/Problem%20Sets/pset4/filters?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 4",
        "name":"Recover",
        "youtubelink":"https://www.youtube.com/embed/3twuWtMqWuI?autoplay=1",
        "placeholderimage":Recover,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/Problem%20Sets/pset4/recover?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Lab 5",
        "name":"Inheritance",
        "youtubelink":"https://www.youtube.com/embed/Y2pVazoCsXc?autoplay=1",
        "placeholderimage":Inheritance,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Labs/lab5.c?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 5",
        "name":"Speller",
        "youtubelink":"https://www.youtube.com/embed/jrXjbnyeWS8?autoplay=1",
        "placeholderimage":Speller,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/Problem%20Sets/pset5/speller?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Lab 6",
        "name":"World Cup",
        "youtubelink":"https://www.youtube.com/embed/W6lv32OgzNk?autoplay=1",
        "placeholderimage":WorldCup,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Labs/lab6.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 6",
        "name":"Mario",
        "youtubelink":"https://www.youtube.com/embed/JjVI9EcdF-I?autoplay=1",
        "placeholderimage":Mario,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset6/mario.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 6",
        "name":"Credit",
        "youtubelink":"https://www.youtube.com/embed/xBPGQt6Jcqo?autoplay=1",
        "placeholderimage":Credit,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset6/credit.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 6",
        "name":"Readability",
        "youtubelink":"https://www.youtube.com/embed/m5d3752PK70?autoplay=1",
        "placeholderimage":Readability2,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset6/readability.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 6",
        "name":"DNA",
        "youtubelink":"https://www.youtube.com/embed/OEdH4Xd7M8M?autoplay=1",
        "placeholderimage":Dna,
        "githublink":"https://github.com/Dors-Coding-School/CS50/blob/main/Problem%20Sets/pset6/dna.py?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Lab 7",
        "name":"Songs",
        "youtubelink":"https://www.youtube.com/embed/UIzhJd1s-E0?autoplay=1",
        "placeholderimage":Songs,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/Labs/lab7?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 7",
        "name":"Fiftyville",
        "youtubelink":"https://www.youtube.com/embed/f8G-NLs-RRM?autoplay=1",
        "placeholderimage":Fiftyville,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/Problem%20Sets/pset7/fiftyville?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 7",
        "name":"Movies",
        "youtubelink":"https://www.youtube.com/embed/riisp8tcVq0?autoplay=1",
        "placeholderimage":Movies,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/Problem%20Sets/pset7/movies?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Lab 8",
        "name":"Trivia",
        "youtubelink":"https://www.youtube.com/embed/XnFQOcxHWPQ?autoplay=1",
        "placeholderimage":Trivia,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/Labs/lab8?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 8",
        "name":"Homepage",
        "youtubelink":"https://www.youtube.com/embed/bIrCjjyZFf0?autoplay=1",
        "placeholderimage":Homepage,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/Problem%20Sets/pset8?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Lab 9",
        "name":"Birthday",
        "youtubelink":"https://www.youtube.com/embed/zHMmViOLOXA?autoplay=1",
        "placeholderimage":Birthday,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/Labs/lab9?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Problem Set 9",
        "name":"Finance",
        "youtubelink":"https://www.youtube.com/embed/8rjuV6VvdZI?autoplay=1",
        "placeholderimage":Finance,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/Problem%20Sets/pset9?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    },
    {
        "title":"Final Project",
        "name":"Final Project",
        "youtubelink":"https://www.youtube.com/embed/b9Eu5BQVs0k?autoplay=1",
        "placeholderimage":FinalProjectx,
        "githublink":"https://github.com/Dors-Coding-School/CS50/tree/main/Problem%20Sets/pset8?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=bJE1qWixVOCp"
    }
]

export default CS50x