import React, {useEffect} from 'react'
import './CodeSolutions.css'
import NavBar from '../../components/NavBar/NavBar';
import TopMold from '../../images/codeSolutionsTopMold.svg'
import TopMoldIcon from '../../images/TopMoldIcon.webp'
import FCCIcon from '../../images/freeCodeCampIcon.webp'
import SQLliteIcon from '../../images/SqlLiteIcon.webp'
import PythonIcon from '../../images/PythonIcon.webp'
import CIcon from '../../images/CIcon.webp'
import CS50Icon from '../../images/CS50Icon.webp'
import CourseCard from '../../components/CodeSolutions/CourseCard';
import BlankCourseCard from '../../components/CodeSolutions/BlankCard'
import cs50xCard from '../../images/solutionsCourseCards/cs50x.webp'
import cs50pythonCard from '../../images/solutionsCourseCards/cs50python.webp'
import cs50webCard from '../../images/solutionsCourseCards/cs50web.webp'
import cs50aiCard from '../../images/solutionsCourseCards/cs50ai.webp'
import cs50sqlCard from '../../images/solutionsCourseCards/cs50sql.webp'
import cs50cybersecurity from '../../images/solutionsCourseCards/cs50cybersecurity.webp'
import web3 from '../../images/solutionsCourseCards/web3card.webp'
/*import frontEndCard from '../../images/solutionsCourseCards/frontend.webp'*/
import responsiveWebDesignCard from '../../images/solutionsCourseCards/responsivewebdesign.webp'
import odinProjectCard from '../../images/solutionsCourseCards/odinprojectcard.webp'
import sqlBoltCard from '../../images/solutionsCourseCards/sqlboltcard.webp'
import codingBatCard from '../../images/solutionsCourseCards/codingbat.webp'
import {trackMixpanel } from '../../mixpanelWrapper';

function CodeSolutions() {

    useEffect(() => {
        trackMixpanel('VisitCodeSolutionsPage');
    }, []);

    const handleButtonClick = () => {
        trackMixpanel('ClickToSalesPageFromCodeSolutions');
    };

    const courseCardDataLeft = [
        { title: ["CS50", "x"], image: cs50xCard, url:"/solutions/cs50x"},
        { title: ["CS50", "python"], image: cs50pythonCard, url:"/solutions/cs50python"},
        { title: ["CS50", "web"], image: cs50webCard, url:"/solutions/cs50web" },
        { title: ["CS50", "ai"], image: cs50aiCard, url:"/solutions/cs50ai"},
        { title: ["CS50", "sql"], image: cs50sqlCard, url:"/solutions/cs50sql"},
        { title: ["CS50", "cybersecurity"], image: cs50cybersecurity, url:"/solutions/cs50cybersecurity" }
    ]

    const courseCardDataCenter = [
        { title: ["WEB", "3"], image: web3, url:"/solutions/web3" },
        /*{ title: ["Frontend", "Development"], image: frontEndCard },*/
        { title: ["Responsive", "WebDesign"], image: responsiveWebDesignCard, url:"/solutions/responsivewebdesign" },
    ]

    const courseCardDataRight = [
        { title: ["Odin", "Project"], image: odinProjectCard, url:"/solutions/odinproject" },
        { title: ["SQL", "Bolt"], image: sqlBoltCard, url:"/solutions/sqlbolt" },
        { title: ["Coding", "Bat"], image: codingBatCard, url:"/solutions/codingbat" },
    ]

    return(
        <div className='code-solutions-screen'>
            <NavBar />
            <div className='code-solutions-top-mold'>
                <img src={TopMold} alt="TopMold" />
                <div className='code-solutions-top-mold-icon-container'>
                    <p>Coding<br/>Resources</p>
                    <div className='code-solutions-top-mold-icon'>
                        <img src={TopMoldIcon} alt="TopMoldIcon" />
                    </div>
                </div>
            </div>
            <div className='code-solutions-first-p'>
                <p>Get Our Solutions of <span>CS50</span>, <span>CS50 Python</span>,</p>
                <p><span>CS50 Web</span>, <span>The Odin Project</span>, <span>SQL Bolt</span>, </p>
                <p><span>CodingBat</span>, and more!</p>
            </div>
            <div className='code-solutions-top-icons-container'>
                <img src={FCCIcon} alt=''></img>
                <img src={SQLliteIcon} alt=''></img>
                <img src={PythonIcon} alt=''></img>
                <img src={CIcon} alt=''></img>
                <img src={CS50Icon} alt=''></img>
            </div>
            <div className='code-solutions-second-p'>
                <p><strong>By the way, </strong>did you know you can</p>
                <p>schedule <span>private classes </span>with us?</p>
            </div>
            <div className='code-solutions-private-button-container'>
                <a href="/coachingplans" target="_blank" rel="noopener noreferrer"><button onClick={handleButtonClick} className='code-solutions-private-button'>Check it out now!</button></a>
            </div>
            <div className='code-solutions-card-columns-container'>
                <div className='code-solutions-card-columns-left'>
                    <h1>CS50</h1>
                    {courseCardDataLeft.map((card, index) => (
                        <CourseCard key={index} cardTitle={card.title} cardImage={card.image} cardUrl={card.url} />
                    ))}
                </div>
                <div className='code-solutions-card-columns-center'>
                    <h1>FREECODECAMP</h1>
                    {courseCardDataCenter.map((card, index) => (
                        <CourseCard key={index} cardTitle={card.title} cardImage={card.image} cardUrl={card.url} />
                    ))}
                    {Array.from({ length: 4 }, (_, index) => (
                        <BlankCourseCard key={index} />
                    ))}
                </div>
                <div className='code-solutions-card-columns-right'>
                    <h1>OTHER PLATFORMS</h1>
                    {courseCardDataRight.map((card, index) => (
                        <CourseCard key={index} cardTitle={card.title} cardImage={card.image} cardUrl={card.url} />
                    ))}
                    {Array.from({ length: 3 }, (_, index) => (
                        <BlankCourseCard key={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CodeSolutions