import React from 'react'
import './WeekDetailScreen.css'
import { Link } from 'react-router-dom'

function ExerciseCard({ id, title, number }) {
  return (
    <Link to={`/problem/${id}`}>
        <div className='exercise-card'>
            <div className='exercise-card-number'><p>{number}</p></div>
            <div className='exercise-card-title'><p>{title}</p></div>
        </div>
    </Link>
  )
}

export default ExerciseCard