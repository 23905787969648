import React, { useEffect } from 'react';
import NewNavBar from '../../components/NavBar/NavBarLandingPage';

const TidyCalEmbed = () => {
    useEffect(() => {
        // Load the TidyCal script
        const script = document.createElement('script');
        script.src = 'https://assets.tidycal.com/js/embed.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup the script when the component unmounts
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
            <NewNavBar />
            <div className="tidycal-embed" data-path="codingdors/30-minute-meeting"></div>
        </div>
    );
};

export default TidyCalEmbed;
