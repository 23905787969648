import React, { useState } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import './ContactPage.css';
import '../QuizScreens/Quiz.css'

const ContactPage = () => {
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [sucess, setSucess] = useState('')

    function handleMessageChange(event) {
        setMessage(event.target.value);
    }

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    async function handleClick() {
        setLoading(true);
        const response = await fetch('/api/proxy-to-zapier/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, message }),
        });
        console.log(response)
        if (response.ok) {
            setSucess('Message sent successfully!');
            setEmail('');
            setMessage('');
        } else {
            setSucess('Failed to send message.');
        }
        setLoading(false);
    }

    return (
        <div className='screen-container-contribute'>
            <NavBar />
            <div className='contribute-screen'>
                <h1 className="h1-contribute-page">Contact Us</h1>
                
                <h2 className="h2-contribute-page">
                    We'd love to hear from you! Please fill out the form below to send us a message.
                </h2>

                <br/>
                <h2 className='h2-contribute-page'>
                    <strong>Email:</strong> giovannasp@dorscodingschool.com
                </h2>

                <br/>
                <div className='contact-card'>
                    <label htmlFor="email" className='label-last-page-quiz-survey'>Email *</label>
                    <input 
                        className='contact-page-email' 
                        placeholder='Email...' 
                        type="text" 
                        value={email} 
                        onChange={handleEmailChange}
                        required 
                    />

                    <label htmlFor="message" className='label-last-page-quiz-survey'>Message *</label>
                    <textarea
                        className='contact-page-message' 
                        placeholder='Message...'
                        value={message}
                        onChange={handleMessageChange}
                        required
                    ></textarea>

                    <div className='survey-button-div'>
                        <button 
                            className='contact-page-button' 
                            onClick={handleClick}
                            disabled={loading || email === '' || message === ''}
                        >
                            {loading ? 'Sending...' : 'Send Message!'}
                        </button>
                    </div>
                    {sucess && <p style={{ color: 'white' }}>{sucess}</p>}
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
