import React from 'react'
import scheduleMeetingLogo from '../../../images/scheduleMeetingLogo.png'
import { trackMixpanel } from '../../../mixpanelWrapper';

function BootcampContentPayment() {
  return (
    <div className='bootcamp-black-div'>
        <div className='bootcamp-price-row'>
            <div className='bootcamp-price-row-left'>
                <h2>3-Month Program</h2>
                <h2>3 Hours Per Week</h2> 
                <span>(Tue & Thu at 6pm CET) OR (Tue & Thu at 6pm PST)</span>
            </div>
            <div className='bootcamp-price-row-right'>
                <h1>$1000 <span>/total</span></h1>
            </div>
        </div>
        <div className='bootcamp-price-row-meeting'>
            <div className='bootcamp-price-row-meeting-left'>
                <h2>Want to Learn</h2>
                <h2>More About our Bootcamp?</h2>
                <p>Schedule a FREE 30-minute meeting with us!</p>
            </div>
            <div className='bootcamp-price-row-meeting-right'>
                <a href='https://tidycal.com/codingdors/30-minute-meeting' target='_blank' rel="noreferrer" onClick={trackMixpanel('ScheduleMeetingBootcamp')}>
                    <button className='bootcamp-price-row-meeting-button'>
                        <img src={scheduleMeetingLogo} alt='' style={{ width: '50px', marginRight: '10px' }} />
                        <div>
                            <p>SCHEDULE</p>
                            <p>YOUR</p>
                            <p>MEETING!</p>
                        </div>
                    </button>
                </a>
            </div>
        </div>
        <p>
            📚 You will start by learning the foundational programming languages of <strong>Python,</strong> <br/>
            <strong>SQL, HTML, and CSS</strong>. These languages will give you a <strong>strong foundation</strong> for the <br/>
            basics of programming and web development.
        </p>
        <p className='bootcamp-p-right'>
            💡Next, you will learn JavaScript, a powerful and popular programming language <br/> 
            that is essential for building interactive web applications. You will also learn <strong>Django</strong> <br/>
            <strong>and Flask</strong>, two popular frameworks for building web applications in Python.
        </p>
        <p>
            🚀 Finally, you will round out your education by learning <strong>React</strong>, a popular library for <br/> 
            building user interfaces. By the end of the bootcamp, you will have a <strong>strong portfolio</strong> <br/> 
            <strong>of projects</strong> to show to potential employers, as well as the skills and confidence to <br/>
            <strong>succeed in a job as a programmer</strong>.
        </p>
    </div>
  )
}

export default BootcampContentPayment