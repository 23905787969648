import React, { useState } from "react";

function BootcampTestimonyCards( {testimonyCardsName, testimonyCardsOccupation, testimonyCardsVideoLink, salesPageTestimonyCardsPlaceholder} ) {
    const [videoLoaded, setVideoLoaded] = useState(false);

    const handleVideoLoad = () => {
        setVideoLoaded(true);
    };

    return (
        <div className="bootcamp-testimony-card-container">
            <div className="bootcamp-testimony-card-name">
                {testimonyCardsName}
            </div>
            <div className="bootcamp-testimony-card-occupation">
                {testimonyCardsOccupation}
            </div>
            <div className="bootcamp-testimony-card-video-container" onClick={handleVideoLoad}>
                {!videoLoaded ? (
                    <>
                        <img
                            loading="lazy"
                            src={salesPageTestimonyCardsPlaceholder}
                            alt="Video Placeholder"
                            className="bootcamp-testimony-cards-video-placeholder"                               
                        />
                        <div 
                            loading="lazy"
                            style={{
                                position: 'absolute',
                                color: 'white',
                                fontSize: '60px',
                                pointerEvents: 'none' // Ensures that the click event is passed through to the image below
                            }}
                        >
                        ▶
                        </div>
                    </>
                ) : (
                    <iframe src={`${testimonyCardsVideoLink}?autoplay=1`} title="Testimony-Card-Video" className="bootcamp-testimony-card-video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                )}
            </div>
        </div>
    )
}

export default BootcampTestimonyCards