import React from 'react'
import './topSessionInfo.css'
import DorsLogo from '../../../images/dorsLogo.webp'

function topSessionInfo() {
    return (
        <div className='landing-page-top-info-container'>
            <div>
                <img className='landing-page-dors-logo' src={DorsLogo} alt=''></img>
            </div>
            <div className='landing-page-top-session-paragraph-1'>
                <p>Get Our Solutions</p>
                <p>and <strong>eBook</strong></p>
            </div>
            <div className='landing-page-top-session-paragraph-2'>
                <p>"The Top Mistakes People Make</p>
                <p>when <span className='landing-page-top-session-paragraph-2-color'>Learning How to Code</span>"</p>
            </div>
        </div>
    )
}

export default topSessionInfo