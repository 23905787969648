import Search from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Search.webp"
import Wiki from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Wiki.webp"
import Commerce from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Commerce.webp"
import Mail from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Mail.webp"
import Network from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Network.webp"

const CS50web = [
    {
        "title":"Project 0",
        "name":"Search",
        "youtubelink":"https://www.youtube.com/embed/DsIkWHKCuL8?autoplay=1",
        "placeholderimage":Search,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20Web/search?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=itzohAdvQmCp"
    },
    {
        "title":"Project 1",
        "name":"Wiki",
        "youtubelink":"https://www.youtube.com/embed/dzxROgoafOs?autoplay=1",
        "placeholderimage":Wiki,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20Web/wiki?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=itzohAdvQmCp"
    },
    {
        "title":"Project 2",
        "name":"Commerce",
        "youtubelink":"https://www.youtube.com/embed/CrowZE3gSJo?autoplay=1",
        "placeholderimage":Commerce,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20Web/commerce?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=itzohAdvQmCp"
    },
    {
        "title":"Project 3",
        "name":"Mail",
        "youtubelink":"https://www.youtube.com/embed/rjHx4LZB89s?autoplay=1",
        "placeholderimage":Mail,
        "githublink":"https://app.kartra.com/download/f8feb997245fe8fdac2ea8e092bc735d69d56b3d3912399e4127bc6aaa598e1b1ff023e38b33864618cd10a5ba0afb356e67a723e0cb3bd479a72415dd1b97bfNXpAWFa7ysImBwBoLjHaMkAKdGYyFQ84rEdnsnSxZ5RPm%2BBh01%2BrPT8K99hkbvRMeAS2S%2B1QIoaecObgpwpEWmGbuV%2BOI1AU4BfiaFNtSlsAuSKb0apW5Rn2aN5DPZfuqM5lK2Qw47I7b2E1aJbsRA%3D%3D?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=itzohAdvQmCp"
    },
    {
        "title":"Project 4",
        "name":"Network",
        "youtubelink":"https://www.youtube.com/embed/Ej7LRWJo8h8?autoplay=1",
        "placeholderimage":Network,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20Web/network?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=itzohAdvQmCp"
    }
]

export default CS50web