import React from 'react'
import './TestsTable.css'

function TestsTableCongrats({ tests, title }) {
  // Extracting the keys of the tests object using Object.keys() method and storing them in an array called keys.
  const ids = Object.keys(tests);

  // Mapping the array of keys to generate an array of elements called items.
  // Each item is a table row containing the data for a particular test case.
  const unitTests = ids.map((id) => {
    const unitTest = tests[id];
    return (
      <tr key={unitTest.id} className='unit-test-tr-td'>
        <td className='unit-test-td-title-congrats'>{title}({unitTest.parameter ? JSON.stringify(unitTest.parameter) : ''})</td>
        <td className='unit-test-td-expected-congrats'>{JSON.stringify(unitTest.expected)}</td> 
        <td className='unit-test-td-run-congrats'>{JSON.stringify(unitTest.run)}</td>
        <td className='unit-test-td-color-congrats'>
            <div style={{ 
                backgroundColor: unitTest.result === true ? '#05FF00' : '#FF0000', 
                boxShadow: unitTest.result === true ? '2px 3px 6px rgba(0, 0, 0, 0.15)' : '2px 3px 6px rgba(0, 0, 0, 0.15)',
                width: "15px", 
                height: "15px", 
                maxWidth: "15px",
                maxHeight: "15px",
                borderRadius: "7.5px" 
            }}></div>
        </td>
    </tr>
    );
  });
  
  return (
    <div className='container-unit-test'>
      <div className='unit-test-table-wrapper'>
        <table className='unit-test-table-congrats table table-borderless'>
          <thead className='unit-test-thead-congrats'>
            <tr className='unit-test-tr-th-congrats'>
              <th className='unit-test-th-congrats unit-test-th-title-congrats'>Test</th>
              <th className='unit-test-th-congrats unit-test-th-expected-congrats'>Expected</th>
              <th className='unit-test-th-congrats unit-test-th-run-congrats'>Run</th>
              <th className='unit-test-th-color-congrats'></th> 
            </tr>
          </thead>
          <tbody className='unit-test-tbody-congrats always-show-scrollbar'>
            {unitTests}
          </tbody>
        </table>
      </div> 
    </div> 
  )
}

export default TestsTableCongrats