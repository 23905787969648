import React, { useEffect } from 'react'
import NavBar from '../../components/NavBar/NavBar'
import BootcampAICurriculum from '../../components/BootcampAI/BootcampAICurriculum'
import BootcampAIVideo from '../../components/BootcampAI/BootcampAIVideo'
import BootcampAISyllabus from '../../components/BootcampAI/BootcampAISyllabus'
import BootcampHeaderAI from '../../components/Bootcamp/BootcampHeader/BootcampHeaderAI'
import BootcampFormAI from '../../components/Bootcamp/BootcampForm/BootcampFormAI'
import BootcampContent from '../../components/Bootcamp/BootcampContent/BootcampContent'
import BootcampAIBenefits from '../../components/BootcampAI/BootcampAIBenefits'
import BootcampTestimonials from '../../components/Bootcamp/BootcampTestimonials/BootcampTestimonials'
import BootcampSummary from '../../components/Bootcamp/BootcampSummary/BootcampSummary'
import BootcampAIFAQ from '../../components/BootcampAI/BootcampAIFAQ'
import { trackMixpanel } from '../../mixpanelWrapper';

function AIBootcampPage() {
  useEffect(() => {
    trackMixpanel('BootcampAIVisit');
  }, []);

  return (
    <div>
        <NavBar/>
        <BootcampHeaderAI />
        <BootcampFormAI senderTag='eZmkyv' />
        <BootcampContent />
        <BootcampAIBenefits />
        <BootcampAIVideo />
        <BootcampAISyllabus />
        <BootcampFormAI senderTag='eZmkyv' />
        <BootcampAICurriculum />
        <BootcampSummary />
        <BootcampTestimonials />
        <BootcampFormAI senderTag='eZmkyv' />
        <BootcampAIFAQ />
    </div>
  )
}

export default AIBootcampPage