import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import './WeekDetailsScreen.css'
import NavBar from '../../components/NavBar/NavBar';
import ExercisesBox from '../../components/WeekDetailScreen/ExercisesBox';
import Spinner from 'react-bootstrap/Spinner';
import {trackMixpanel } from '../../mixpanelWrapper';

function WeekDetailsScreen() {
    const { number } = useParams();

    const [exercises, setExercises] = useState([]);
    const [loadingQuestion, setLoadingQuestion] = useState(false);

    useEffect(() => {
        // Set the loading state for the question to true at the beginning
        setLoadingQuestion(true);

        // Define an asynchronous function to fetch exercises
        async function fetchExercises() {
        try {
            // Track the event 'WeekProblems' for analytics
            trackMixpanel('WeekProblems');

            // Default course name
            var courseName = 'CS50 Python'

            // Check if the week number is between 0 and 19 (inclusive)
            if(number >= 0 && number <= 19){
                // If so, set the course name for Python exercises
                courseName = 'CS50 Python';
            } else {
                // Otherwise, for week numbers greater than 19, set the course for C exercises
                courseName = 'CS50x';
            }

            // Fetch exercises from the server based on the course name and week number
            const response = await fetch(`/course_exercises/${courseName}/${number}/`);
            // Parse the response as JSON
            const data = await response.json();

            // Set the loading state for the question to false after fetching
            setLoadingQuestion(false)
            // Update the state with the fetched exercises
            setExercises(data);
        } catch (error) {
            // Log any errors encountered during the fetch operation
            console.error('Error fetching exercises:', error);
        }
        }

        // Execute the fetchExercises function
        fetchExercises();

    }, [number]); // useEffect depends on the 'number' variable

  
    return (
        <div className='screen-container week-details-screen'>
            <NavBar />
            <div className='container-week-detail'>
                {loadingQuestion ? 
                    <div className="spinner-container">
                        <Spinner animation="border" role="status">
                        </Spinner>
                    </div> 
                :
                    <ExercisesBox exercises={exercises} week={number}/>
                }
            </div>
            
        </div>
    );
}
  
export default WeekDetailsScreen
