import React, { useState } from 'react'
import './FormCard.css'
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import {trackMixpanel } from '../../../mixpanelWrapper';

function FormCard() {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    function handleFirstNameChange(event){
        setFirstName(event.target.value)
    }

    function handleEmailChange(event){
        setEmail(event.target.value)
    }

    // Function to get test results
    const submitUserData = async () => {
        try {
            console.log("Submit Data: Started");
            setLoading(true);
    
            const formData = new FormData();
            formData.append('firstName', firstName);
            formData.append('email', email);
            formData.append('tag_name', 'aAQ7Lp');
    
            const response = await fetch('/get_user_data_quiz/', {
                method: 'POST',
                body: formData
            });
    
            const data = await response.json();
            console.log(data.message)
            console.log(data.data)
            if(data.message === "Error creating lead"){
                setErrorMessage(data.message)
            }
            return data.status === 'ok'; // Return true if status is 'ok'
    
        } catch (error) {
            console.error('submitUserData: An error occurred:');
            setErrorMessage(error)
            return false;
        } finally {
            setLoading(false);
            console.log("Submit Data: Ended");
        }
    };

    async function handleClick() {
        trackMixpanel('NewLeadLandingPage');
        const isSubmissionSuccessful = await submitUserData();
        if (isSubmissionSuccessful) {
            navigate(`/thankyou`);
        }
    }

    return (
        <div className='form-container'>
            <div className='form-background'>
                <div className='paragraph'>
                    <p>GET OUR <strong>SOLUTIONS</strong></p>
                    <p>AND <strong>EBOOK</strong></p>
                    <p>FOR <strong>FREE</strong></p>
                    <p>NOW</p>
                </div>
                <div className='survey-container'>
                    <label htmlFor="first-name" className='label-form-card-survey'>First name *</label>
                        <input 
                            className='form-card-survey-first-name' 
                            placeholder='First Name...' 
                            type="text" 
                            id="first-name" 
                            value={firstName}
                            onChange={handleFirstNameChange} 
                            required 
                            />

                        <label htmlFor="email" className='label-form-card-survey'>Email *</label>
                        <input 
                            className='form-card-survey-email' 
                            placeholder='Email...' 
                            type="text" 
                            value={email} 
                            onChange={handleEmailChange}
                            required 
                        />
                </div>
                {/* Generate the loading spinner when evaluating the answer */}
                {loading && 
                    <div className="spinner-container" style={{ width: '80%', height: '5px', color: 'white', marginTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner animation="border" role="status"></Spinner>
                    </div>  
                }

                {/* Display error message, if there is any */}
                {errorMessage && <div className='error-message-quiz'><p>{errorMessage}. Try again!</p></div>}
                
                <div className='submit-button-container'>
                    <button 
                        className='form-card-survey-button'
                        onClick={handleClick}
                        disabled={email==='' && firstName===''}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FormCard