import React from 'react'
import BootcampTestimonyCards from './BootcampTestimonyCards'
// import ryanVideoPlaceholderImage from '../../../images/landingPageRyanPlaceholder.webp';
// import alexVideoPlaceholderImage from '../../../images/landingPageAlexPlaceholder.webp';
// import elieVideoPlaceholderImage from '../../../images/landingPageEliePlaceholder.webp';
import AntoinePlaceholderImage from '../../../images/bootcamp/AntoinePlaceholder.webp'
import VictorPlaceholderImage from '../../../images/bootcamp/VictorPlaceholder.webp'
import SKPlaceholderImage from '../../../images/bootcamp/SKPlaceholder.webp'

function BootcampTestimonials() {
  return (
    <div className='bootcamp-testimonial-section'>
        <div className='bootcamp-testimonial-header'>
            <svg xmlns="http://www.w3.org/2000/svg" width="280" height="143" viewBox="0 0 280 143" fill="none">
                <path d="M149.862 112.334L225.806 69.9217L233.077 117.443L71.3309 142.19L64.201 95.591L149.862 112.334ZM254.356 81.8622L248.901 46.2074L279.431 29.1651L132.055 0.374975L0.946459 73.5873L148.328 102.384L239.543 51.4613L244.427 83.3813C241.411 85.6866 239.603 89.4043 240.217 93.4174C241.109 99.2508 246.571 103.276 252.415 102.382C258.255 101.489 262.263 96.0103 261.371 90.1807C260.769 86.1465 257.923 83.1562 254.356 81.8622Z" fill="black"/>
            </svg>
            <h1>Our Past<br/><span>Students</span></h1>
        </div>
        <p className='bootcamp-testimonial-section-p'>
            We've been dedicated to providing coding education for some time.<br/>
            See what our students have to say about their experience with us.
        </p>
        <div className='bootcamp-testimonial-div'>
            <BootcampTestimonyCards testimonyCardsName='Victor Gallardo' testimonyCardsOccupation='USA' testimonyCardsVideoLink='https://www.youtube.com/embed/6SbEGQ9Wg5k' salesPageTestimonyCardsPlaceholder={VictorPlaceholderImage}/>
            <BootcampTestimonyCards testimonyCardsName='SK Soh' testimonyCardsOccupation='Singapore' testimonyCardsVideoLink='https://www.youtube.com/embed/quC1EKSRu_Y' salesPageTestimonyCardsPlaceholder={SKPlaceholderImage}/>
            <BootcampTestimonyCards testimonyCardsName='Antoine Nitzki' testimonyCardsOccupation='Spain' testimonyCardsVideoLink='https://www.youtube.com/embed/mVnilLoxMW0' salesPageTestimonyCardsPlaceholder={AntoinePlaceholderImage}/>
        </div>
    </div>
  )
}

export default BootcampTestimonials