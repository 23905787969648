import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';

// Import the Ace Editor mode for Python and the themes
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';
// This mode handles both C and C++
import 'ace-builds/src-noconflict/mode-c_cpp'; 


function CodeEditor(props) {
  const [fontSize, setFontSize] = useState(16);
  const [editorInstance, setEditorInstance] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if(window.matchMedia('(min-width: 1800px)').matches) {
        setFontSize(24);
      } else if(window.matchMedia('(min-width: 2500px)').matches) {
        setFontSize(28);
      } else if(window.matchMedia('(min-width: 700px)').matches) {
        setFontSize(16);
      }else {
        setFontSize(12);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the function initially

    // Cleanup on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  useEffect(() => {
    // Check if the editor instance is available
    if (editorInstance) {
      // Focus on the editor when the component mounts or updates
      editorInstance.focus();

      // Check if the language selected is C
      if (props.language === 'C') {
        // After setting the value for the C mode
        editorInstance.renderer.updateFull();
        editorInstance.resize();
        // Set the editor mode to C/C++
        editorInstance.getSession().setMode('ace/mode/c_cpp');
  
        // Format the incoming C code to properly display newlines
        const formattedCValue = props.value.replace(/\\n/g, '\n');
        editorInstance.getSession().setValue(formattedCValue);
  
        // Retrieve all lines from the editor's document
        const lines = editorInstance.getSession().getDocument().getAllLines();

        // Find the line number that contains the comment '/* Add code here */'
        const lineNumber = lines.findIndex(line => line.includes('/* Add code here */'));

        // Move the cursor to two lines below the comment, at the start of the line
        editorInstance.gotoLine(lineNumber + 2, 4); 
      } else {
        // For other languages, particularly Python
        const formattedValue = props.value.replace(/\\n/g, '\n');

        editorInstance.getSession().setValue(formattedValue);

        // Get the total number of lines in the editor
        let count = editorInstance.getSession().getLength();
        // Move the cursor to the end of the last line
        editorInstance.gotoLine(count, editorInstance.getSession().getLine(count - 1).length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorInstance, props.language]); // Depend on editorInstance and props.language

  const onLoad = (editor) => {
    setEditorInstance(editor);

    // Optional: Resetting the editor content to remove any mixed indentation from previous sessions
    editor.setValue(editor.getValue().replace(/\\t/g, '    ')); // Replace tabs with four spaces
  
    // Configure the editor to use soft tabs (convert tabs to spaces)
    editor.getSession().setUseSoftTabs(true);
  
    // Set the number of spaces that represent a tab (common standard for Python is 4)
    editor.getSession().setTabSize(4);

    // Disable the syntax validator worker
    editor.getSession().setUseWorker(false);
  
    // Optionally, to help users see the difference between tabs and spaces, enable showing invisibles
    //editor.setShowInvisibles(true);
  };
  
  const onBeforeLoad = (ace) => {
    ace.require('ace/ext/language_tools');
    ace.require('ace/edit_session').EditSession.prototype.setUseSoftTabs(true);
    ace.require('ace/editor').Editor.prototype.setBehavioursEnabled(false);
  };

  return (
    <AceEditor
      mode={props.language === 'C' ? 'c_cpp' : 'python'}
      theme="monokai"
      name="my-editor"
      value={props.value}
      onChange={props.onChange}
      fontSize={fontSize}
      showPrintMargin={false}
      showGutter={true}
      highlightActiveLine={true}
      onBeforeLoad={onBeforeLoad}
      onLoad={onLoad}
      setOptions={{
        useWorker: false,
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 4,
        useSoftTabs: true,
        //behavioursEnabled: true // This enables automatic indentation handling
      }}
      style={{
        width: '100%',
        minHeight: '300px',
        height: '50%',
        fontFamily: 'Monaco, Menlo, Ubuntu Mono, Consolas, "Courier New", monospace',
      }}
    />
  );
}

export default CodeEditor;
