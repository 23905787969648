import React, { useState } from 'react';

function WeekButton({ title, image, primaryColor, secondaryColor, terciaryColor, goToLink }) {
    const [backgroundColor, setBackgroundColor] = useState(primaryColor);
    const [buttonActive, setButtonActive] = useState(false); // added this line
    
    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    }

    // This will stop the event from bubbling up and causing the overlay to close the popup
    const handlePopupClick = (e) => {
        e.stopPropagation();
    }

    // Check if we need to open the link in a new tab (if it is not from the website)
    const checkProtocol = () => {
        return goToLink.startsWith('https://');
      }

    return (
        <div style={{ position: 'relative' }}>
            <button 
                className='week-button-format' 
                style={{
                    backgroundColor: backgroundColor, // changed this line
                    border: backgroundColor,
                    boxShadow: buttonActive? 'none' : `0 4px 0 2px ${terciaryColor}`,
                }}
                onClick={togglePopup}
                onMouseEnter={() => setBackgroundColor(terciaryColor)}
                onMouseLeave={() => setBackgroundColor(primaryColor)}
                onMouseDown={() => setButtonActive(true)} // added this line
                onMouseUp={() => setButtonActive(false)} // added this line
            >
                <img src={image} alt='icon' className="icon-week-button" />
            </button>
            {showPopup &&
                <>
                    <div 
                        onClick={togglePopup}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            zIndex: 3
                        }}
                    ></div>
                    <div 
                        onClick={handlePopupClick}
                        className='popup-week-unit'
                        style={{ 
                            backgroundColor: secondaryColor,
                            border: secondaryColor,
                            boxShadow: `0 4px 0 0 ${terciaryColor}`,
                            zIndex: 4, // Increase z-index to show popup above the overlay
                            position: 'absolute', // Ensure popup appears in the right place
                        }}
                    >
                        <h6>{title}</h6>
                        {checkProtocol() ? 
                            <a href={goToLink} target='_blank' rel="noreferrer">
                                <button 
                                    onClick={togglePopup}
                                    style={{
                                        backgroundColor: primaryColor,
                                        border: primaryColor,
                                        boxShadow: `0 4px 0 2px ${terciaryColor}`
                                    }}
                                >
                                    Start
                                </button>
                            </a>
                        :
                            <a href={goToLink}>
                                <button 
                                    onClick={togglePopup}
                                    style={{
                                        backgroundColor: primaryColor,
                                        border: primaryColor,
                                        boxShadow: `0 4px 0 2px ${terciaryColor}`
                                    }}
                                >
                                    Start
                                </button>
                            </a>
                        }
                        
                    </div>
                </>
            }
        </div>
    )
}

export default WeekButton;

