import React from "react";
import './PricingCards.css'
import { trackMixpanel } from '../../../mixpanelWrapper';

function PricingCards( {style, price, hours, height, cartlink, mixedPanelTag} ) {

    const handleButtonClick = () => {
        trackMixpanel(mixedPanelTag);
    };

    return (
        <div className="sales-page-pricing-cards-container" style={height}>
            <div className="sales-page-pricing-cards-most-common" style={style}><p>Most<br />Common</p></div>
            <div className="sales-page-pricing-cards-price">{price}<br /><span>Monthly</span></div>
            <div className="sales-page-pricing-cards-benefits">
                <ul>
                    <li class="checkmark"><strong>{hours}</strong> Class Every Week</li>
                    <li class="checkmark"><strong>+500</strong> Exclusive Exercises</li>
                    <li class="checkmark"><strong>24/7</strong> Email Support</li>
                </ul>
            </div>
            <a href={cartlink} onClick={handleButtonClick} target="_blank" rel="noopener noreferrer"><button className="sales-page-pricing-cards-button">Add to Cart</button></a>
        </div>
    )
}

export default PricingCards