import React, { useState } from 'react';

function WeekButtonDeactive({ image }) {
    
    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    }

    return (
        <div style={{ position: 'relative' }}>
            <button 
                className='week-button-format' 
                style={{
                    backgroundColor: '#CDCDCD',
                    border: '#CDCDCD'
                }}
                onClick={togglePopup}
            >
                <img src={image} alt='icon' className="icon-week-button" />
            </button>
            {showPopup &&
                <>
                    <div 
                        onClick={togglePopup}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            zIndex: 3
                        }}
                    ></div>
                    <div 
                        className='popup-week-unit'
                        style={{ 
                            backgroundColor: '#626262',
                            border: '#626262',
                            boxShadow: '0 4px 0 0 #6621BD'
                        }}
                    >
                        <h6>Coming Soon...</h6>
                        <button 
                            onClick={togglePopup}
                            style={{
                                backgroundColor: '#8A8A8A',
                                border: '#8A8A8A',
                                boxShadow: '0 4px 0 2px #737373',
                                zIndex: 4,
                            }}
                        >
                            Blocked
                        </button>
                    </div>
                </>
            }
        </div>
    )
}

export default WeekButtonDeactive;