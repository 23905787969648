import SQLIn2Hours from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/SQLIn2Hours.webp"

const SQLBolt = [
    {
        "title":"SQLBolt",
        "name":"SQL in 2 Hours",
        "youtubelink":"https://www.youtube.com/embed/uPrC2IpUXWs?autoplay=1",
        "placeholderimage":SQLIn2Hours,
        "githublink":"https://app.kartra.com/download/9aefdf8e9a7205be81e53e378483ca10f6cd477c462865de33cc427156200cad41c405b77d648263e18901793bc75e815f58358539ebe6546cb8dc0fb0bde3f9SNjk7a9PdB4g%2FQtcevXov5HGoy%2Bv9602AB0I8cxhxYV5ORBtqpr6z19CKI2aIVRou585qKeCmB8sHGIfYoQh88fAmOdcrNUnxaVFp%2FIo7P8LqoQHhutlDXu%2Fyzm1HwQxaOUrsSbsaAwdVb%2B%2FH04L7EP3h7ftJQYYp7Jr3hKY5oE%3D?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=hBxnL8HypMdT"
    }
]

export default SQLBolt