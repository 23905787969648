import React from 'react'
import './WeekDetailScreen.css'
import { Link } from 'react-router-dom'
import proficiencyCardCircleIcon from '../../images/proficiencyCardCircleIcon.png'

function ProficiencyCard({ week }) {
    return (
        <div className='proficiency-card-position'>
            <Link to={`/proficiency/${week}`} >
                <div className='proficiency-card'>
                    <div className='proficiency-card-circle-icon'><img src={proficiencyCardCircleIcon} alt="" /></div>
                    <div className='proficiency-card-title'><p>Proficiency Test</p></div>
                </div>
            </Link>
        </div>
    )
}

export default ProficiencyCard