const faqData = [
    {
        "question":"Will Completing the 1-on-1 Tutoring Guarantee Me a Job as a Developer ?",
        "answer":"We believe that by completing our 1-on-1 tutoring and studying our suggested amount of time (5 hours a week) you are very likely to get a job. If you do not find a job within 1 year, we will keep teaching you CS50 and helping you with your portfolio for free until you do. That means we will keep teaching you the contents of CS50 and focus on getting you a job."
    },
    {
        "question":"Why Should I Have a Mentor?",
        "answer":"Having a mentor can be a valuable source of encouragement, motivation and accountability in achieving your goals in programming. We provide guidance and support, help with navigating challenging problems, offer insights into industry best practices, and expose you to new technologies and approaches."
    },
    {
        "question":"How Do the Classes Work ?",
        "answer":"In our 1-on-1 tutoring sessions, we provide weekly classes with instructors to address any questions or questions you may have. The classes are designed to be a collaborative experience, with our instructors serving as a partner in the learning process. During the class, you will have the opportunity to explain each step of the code and receive feedback on your approach, as well as benefit from the instructors' alternative perspectives. To further reinforce your understanding, you will be assigned homework after each class, which may include watching a Harvard lecture or additional coding exercises. Our students also have access to 24/7 support via slack, so they never have to feel stuck or frustrated while working on their assignments."
    },
    {
        "question":"What Are the Available Times to Have a Class?",
        "answer":"We understand the importance of accommodating our clients' schedules, which is why we are available to assist you from 6am to 6pm Pacific Standard Time during the week. However, if these hours do not work for you, we are willing to find a time that better fits into your routine. Our goal is to ensure that you receive the support you need when you need it."
    },
    {
        "question":"How Long Will It Take Before I'm Ready for a Career in Programming ?",
        "answer":"The amount of time you dedicate to studying and practicing outside of our classes will greatly impact your progress as a programmer. As a general guideline, if you are a beginner and are able to allocate 5 hours of study on top of our weekly classes, you will be able to start working as a programmer within one year or even less. Remember, the key to success is consistent and dedicated effort, so the more time you spend studying, the quicker you can reach your goal."
    },
    {
        "question":"When and How Can I Cancel My Membership?",
        "answer":"Once you decide to join us, you will have the option to cancel your membership at any time without any long-term obligations. This means that you only pay for the month that you use our services. The process of canceling your membership is straightforward and can be done through our membership platform. We will provide you with an onboarding email, which includes all the instructions you need to cancel your membership if you choose to do so. If you happen to lose this email, please don't hesitate to reach out to us and we will be happy to provide it again."
    },
    {
        "question":"How Can You Help the Students to Get a Job, Once the Student Is Suitable to Apply for a Job?",
        "answer":"During our 1-on-1 tutoring sessions, we will be working on multiple projects together. To help you solidify your understanding and skills, we will guide you in creating a video explanation for each project. By the end of the course, we aim to help you build a website portfolio that showcases your projects and demonstrates your experience. Additionally, we will provide you with weekly goals for job applications and offer additional tips and hints to help you in your job search. Our goal is to support you in achieving your career aspirations as a programmer."
    },
    {
        "question":"I Want to Become a Data Scientist or Learn AI, Can the 1-on-1 Tutoring Help Me?",
        "answer":"For those seeking a career in data science or AI, we have a tailored learning path to support your journey. Our students typically begin by taking CS50 Python to build a strong foundation in coding. From there, they move on to CS50 AI to gain an understanding of AI concepts. Finally, they have the opportunity to delve deeper into machine learning and deep learning by taking additional courses. This learning path is designed to provide a comprehensive education in these areas and help our students achieve their career goals."
    }
]

export default faqData