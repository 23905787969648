import React from 'react'
import NavBar from '../../components/NavBar/NavBar';
import './ThankYouPage.css'
import ThankYouPageDorsLogo from '../../images/dorsLogo.webp'
import ThankYouPageBackgroundLines from '../../images/landingPageBackgroundLines.webp'

import { useNavigate } from 'react-router-dom';
import {trackMixpanel } from '../../mixpanelWrapper';

function ThankYouPage() {
  const navigate = useNavigate();

  async function handleClick() {
    trackMixpanel('VisitThankYouPage');
    navigate(`/python`);
  }

  return (
    <div>
      <NavBar />
      <div className='thank-you-page-screen'>
        <img className='thank-you-page-background-lines' alt='' src={ThankYouPageBackgroundLines}></img>
        <div className='thank-you-page-rectangle'>
            <img className='thank-you-page-dors-logo' alt='' src={ThankYouPageDorsLogo}></img>
            <div className='thank-you-page-first-paragraph'>
              <p>Thanks for Signing Up!</p>
              <p>Check your mailbox!</p>
            </div>
            <div className='thank-you-page-second-paragraph'>
              <p>We've just sent an email with our ebook</p>
              <p>about "The Top Mistakes People Make</p>
              <p> When Learning How to Code".</p>
            </div>
            <div className='thank-you-page-middle-line'></div>
            <div className='thank-you-page-third-paragraph'>
              {/* <p>Take our free quiz and get a</p>
              <p>personalized learning plan</p>
              <p>on what you still need to know</p>
              <p>to become a professional developer.</p> */}
              <p>Explore our comprehensive guide</p>
              <p>to mastering programming concepts,</p>
              <p>tailored for busy schedules.</p>
            </div>
            <div className='thank-you-page-video-container'>
              <iframe className="thank-you-page-quiz-video" src='https://www.youtube.com/embed/vrgPq_bfoaM' title="Quiz Video" frameborder="0" allow="accelerometer" allowfullscreen></iframe>
            </div>
            <button 
              className='thank-you-page-quiz-button'
              onClick={handleClick}
            >
              Start Practice
            </button>
        </div>
      </div>
    </div>
  )
}

export default ThankYouPage