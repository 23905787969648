import React, {useState} from 'react'
import './Tree.css'
import notesIcon from "../../images/notes.png"

function UnitCard({ number, title, primaryColor, secondaryColor, terciaryColor, goToLink }) {
    const [backgroundColor, setBackgroundColor] = useState(secondaryColor);
  
    return (
        <div 
            className='unit-card-container' 
            style={{ 
                backgroundColor: primaryColor, 
                border: primaryColor,
                boxShadow: `2px 2px 0 2px ${secondaryColor}`
            }}
        >
            <div className='unit-card-container-left'>
                <h1 style={{ color: secondaryColor }}>Unit {number}</h1>
                <h2>{title}</h2>
            </div>
            {/* Lecture Button Removed for Now */}
            <div className='unit-card-container-right'>
                <a target='_blank' rel="noreferrer" href={goToLink}>
                    <button 
                        className='btn-lecture'
                        style={{ 
                            backgroundColor: backgroundColor,
                            border: backgroundColor,
                            boxShadow: `0 6px 0 0 ${terciaryColor}`
                        }}
                        onMouseEnter={() => setBackgroundColor(terciaryColor)}
                        onMouseLeave={() => setBackgroundColor(secondaryColor)}
                    >
                        <img src={notesIcon} alt="Notes" className='icon-notes'/>
                        Notes
                    </button>
                </a>
            </div>
        </div>
    )
}

export default UnitCard