import React from 'react'
import './ProficiencyTest.css'
import proficiencyTestLogo from '../../images/proficiencyTestLogo.png';
import { Link } from 'react-router-dom';

function ProficiencyTestResults({ exercises, score, week }) {
    let percentage = score/exercises.length
    let congratsMsg1 = "Though this attempt didn't meet the mark, don't be disheartened. With more practice and dedication, we believe you'll excel next time. Keep going!"
    let congratsMsg2 = "While the test didn't go as planned, don't lose heart. We recommend revisiting this week's exercises to strengthen your understanding and prepare for future tests."
    let nextWeek = week

    const size = 200; // Circle size
    const strokeWidth = 30; // Circle border width
    const radius = size / 3 - strokeWidth / 3 // Circle radius
    const circumference = 2 * Math.PI * radius; // Circle circumference
    const strokeDashoffset = circumference * (1 - percentage); // Trace offset according to percentage

    if(percentage === 1){
        congratsMsg1 = 'Congratulations on your outstanding success in the coding proficiency test!';
        congratsMsg2 = 'Now that you have proven your coding proficiency, I would like to redirect your attention to the following challenging problems:';
        nextWeek = week + 1;
    }

    return (
        <div className='container-proficiency-intro'>
            <div className='card-for-logo'>
                <img src={proficiencyTestLogo} alt="" />
                <div className='proficiency-result-line'></div>
                <h1>Done</h1>
            </div>
            <h1>CONGRATULATIONS.</h1>
            <div className='container-text-result'>
                <div className='container-text-result-left'>
                    <div className='proficiency-result-score-div'>
                        <div className='proficiency-result-score-div-left'>
                            You got {score} out of {exercises.length} problems correct
                        </div>
                        <div className='proficiency-result-score-div-right'>
                            <svg width={size} height={size}>
                                <circle
                                    r={radius}
                                    cx={size / 2}
                                    cy={size / 2}
                                    strokeWidth={strokeWidth}
                                    stroke="gray"
                                    fill="transparent"
                                />
                                <circle
                                    r={radius}
                                    cx={size / 2}
                                    cy={size / 2}
                                    strokeWidth={strokeWidth}
                                    stroke="rgba(253, 181, 0, 1)"
                                    fill="transparent"
                                    strokeDasharray={circumference}
                                    strokeDashoffset={strokeDashoffset}
                                    style={{ transform: "rotate(-90deg)", transformOrigin: "center" }}
                                />
                                <text x="50%" y="50%" textAnchor="middle" dy=".3em" fontSize="22px">
                                    <tspan>{score}</tspan>
                                    <tspan fontSize="22px" fill='rgba(253, 181, 0, 1)'>/{exercises.length}</tspan>
                                </text>
                            </svg>
                        </div>
                    </div>
                    <p>{congratsMsg1}</p>
                </div>
                <div className='container-text-result-right'>
                    <p>{congratsMsg2}</p>
                    <Link to={`/week/${nextWeek}`}>
                        <div className='proficiency-result-card-next-week'>
                            <h6>Week {nextWeek}</h6>
                        </div>
                    </Link>
                    <p>These will help you further enhance your skills and keep you engaged in your <strong>coding journey.</strong></p>
                </div>
            </div>
        </div>
    );
}

export default ProficiencyTestResults
