import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import MobileAlert from '../../components/MobileAlert';
import NavBar from '../../components/NavBar/NavBar';
import ProficiencyTestQuestion from './ProficiencyTestQuestion';
import './ProficiencyTest.css'
import proficiencyTestLogo from '../../images/proficiencyTestLogo.png';

function ProficiencyTestIntroduction() {
    const { week } = useParams();
    const [quizExercises, setQuizExercises] = useState([]);
    const [loadingQuestion, setLoadingQuestion] = useState(false);
    const [startQuiz, setStartQuiz] = useState(false);

    useEffect(() => {
        setLoadingQuestion(true);
        async function fetchExercises() {
            try {
                const courseName = 'CS50 Python'; // Replace this with the correct course name
                const response = await fetch(`/course_exercises/${courseName}/${week}/`);
                let data = await response.json();

                // Shuffle all exercises using Fisher-Yates (aka Knuth) Shuffle
                for (let i = data.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [data[i], data[j]] = [data[j], data[i]]; // swap elements
                }
                
                setQuizExercises(data);
                setLoadingQuestion(false);
            } catch (error) {
                console.error('Error fetching exercises:', error);
            }
        }
        fetchExercises();
    }, [week]);

    const handleStartQuiz = () => {
        setStartQuiz(true);
    }

    return (
        <div className='screen-container proficiency-test-screen'>
            <NavBar />
            <MobileAlert />
            {!loadingQuestion && quizExercises.length > 0 && (
                startQuiz ? 
                    <ProficiencyTestQuestion exercises={quizExercises} week={week} />
                : 
                <div className='container-proficiency-intro'>
                    <div className='card-for-logo'>
                        <img src={proficiencyTestLogo} alt="" />
                    </div>
                    <div className='container-text-intro'>
                        <p>This examination will present you with a <strong>comprehensive set of problems</strong></p>
                        <p>derived from your current unit of study. Be prepared, as you'll only have</p>
                        <p><strong>60 seconds</strong> to solve each problem.</p>
                        <br/>
                        <p>While taking the test, it's essential to <strong>rely on your understanding and skills.</strong> We </p>
                        <p>do <strong>permit</strong> the use of online resources to look up <strong>specific functions for reference</strong></p>
                        <p><strong>purposes.</strong> However, please refrain from seeking the complete solution online.</p>
                    </div>
                    <br/>
                    <h6>Good luck with your test!</h6>
                    <button className='start-test' onClick={handleStartQuiz}>Begin Test</button>
                </div>
            )}
        </div>
    );
}

export default ProficiencyTestIntroduction