import React, { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import '../../../screens/BootcampPage/BootcampPage.css'
import { trackMixpanel } from '../../../mixpanelWrapper';

// BOOTCAMP WAITLIST on Sender - 'e7nZy8'
// BOOTCAMP AI WAITLIST on Sender - 'eZmkyv'

function BootcampForm({ senderTag }) {
    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)

    function handleFirstNameChange(event){
        setFirstName(event.target.value)
    }

    function handleEmailChange(event){
        setEmail(event.target.value)
    }

    // Function to get test results
    const submitUserData = async () => {
        try {
            console.log("Submit Data: Started");
            setLoading(true);
    
            const formData = new FormData();
            formData.append('firstName', firstName);
            formData.append('email', email);
            formData.append('tag_name', senderTag);
    
            const response = await fetch('/get_user_data_quiz/', {
                method: 'POST',
                body: formData
            });
    
            const data = await response.json();
            console.log(data.message)
            if(data.message === "Error creating lead"){
                setErrorMessage(data.message)
            } else{
                setSuccessMessage('Submission Successful')
            }
            return data.status === 'ok'; // Return true if status is 'ok'
    
        } catch (error) {
            console.error('submitUserData: An error occurred:');
            setErrorMessage(error)
            return false;
        } finally {
            setLoading(false);
            console.log("Submit Data: Ended");
            setEmail('')
            setFirstName('')
        }
    };

    function handleClick() {
        trackMixpanel('ClickWaitlist')
        submitUserData()
    }

    return (
        <div className='bootcamp-form-div'>
            <div className='bootcamp-form-div-left'>
                <h1>Sign Up</h1> 
                <h1>For Our</h1>
                <h1>Waiting List.</h1>
            </div>
            <div className='bootcamp-form-div-right'>
            <label htmlFor="first-name" className='label-bootcamp'>First name *</label>
                    <input 
                        className='bootcamp-first-name' 
                        placeholder='First Name...' 
                        type="text" 
                        id="first-name" 
                        value={firstName}
                        onChange={handleFirstNameChange} 
                        required 
                        />

                    <label htmlFor="email" className='label-bootcamp'>Email *</label>
                    <input 
                        className='bootcamp-email' 
                        placeholder='Email...' 
                        type="text" 
                        value={email} 
                        onChange={handleEmailChange}
                        required 
                    />

                    {/* Generate the loading spinner when evaluating the answer */}
                    {loading && 
                        <div className="spinner-container" style={{ width: '80%', height: '5px', color: 'white', marginTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spinner animation="border" role="status"></Spinner>
                        </div>  
                    }

                    {/* Display error message, if there is any */}
                    {errorMessage && <div className='message-bootcamp'><p>{errorMessage}. Try again!</p></div>}

                    {/* Display success message, if there is any */}
                    {successMessage && <div className='message-bootcamp'><p>{successMessage}.</p></div>}

                    <div className='bootcamp-button-div'>
                        <button 
                            className='bootcamp-button' 
                            onClick={handleClick}
                            disabled={email==='' && firstName===''}
                        >
                            Sign Up
                        </button>
                    </div>
            </div>
        </div>
    )
}

export default BootcampForm