import React from 'react'

function BootcampAIBenefits() {
  return (
    <div className='bootcamp-benefits-section'>
        <div className='bootcamp-benefits-card card-benefits-one'>
            <h5>Proven Curriculum</h5>
            <hr />
            <p>
                Our bootcamp leverages the esteemed CS50 Python and CS50 AI courses, renowned for their rigorous and thorough approach to teaching programming and AI concepts. You’ll learn from the same material used by students at Harvard University.
            </p>
        </div>
        <div className='bootcamp-benefits-card card-benefits-two'>
            <h5>Focus on Fundamentals</h5>
            <hr />
            <p>
                We emphasize a deep understanding of fundamental concepts, ensuring you have a solid grasp of Python programming and the core principles of artificial intelligence. This foundation is crucial for tackling more advanced topics and real-world applications.
            </p>
        </div>
        <div className='bootcamp-benefits-card card-benefits-three'>
            <h5>Hands-On Learning</h5>
            <hr />
            <p>
                Our bootcamp is designed to be interactive and practical. You’ll engage in hands-on coding exercises, projects, and real-world scenarios, allowing you to apply what you’ve learned and build a robust portfolio of work.
            </p>
        </div>
        <div className='bootcamp-benefits-card card-benefits-four'>
            <h5>Community and Networking</h5>
            <hr />
            <p>
                Join a vibrant community of like-minded individuals who share your passion for AI and programming. Collaborate on projects, participate in discussions, and build a network that can support your career growth.
            </p>
        </div>

    </div>
  )
}

export default BootcampAIBenefits