import React, { useEffect } from 'react'
import NavBar from '../../components/NavBar/NavBar';
import AutomationsSalesPageHeader from '../../components/AutomationsSalesPage/Header/AutomationsSalesPageHeader';
import AutomationsSalesPageHeader2 from '../../components/AutomationsSalesPage/Header/AutomationsSalesPageHeader2';
import './AutomationsSalesPage.css'
import { trackMixpanel } from '../../mixpanelWrapper';

function AutomationSalesPage() {
    useEffect(() => {
        trackMixpanel('AutomationSalesPageVisit');
    }, []);

    return (
        <div className='automations-sales-page-screen'>
            < NavBar/>
            < AutomationsSalesPageHeader/>
            < AutomationsSalesPageHeader2/>
        </div>
    )
}

export default AutomationSalesPage