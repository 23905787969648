import React, { useState } from "react";
import './TestimonyCard.css'

function TestimonyCard({ name, occupation, video, videoPlaceholderImage}) {

    const [videoLoaded, setVideoLoaded] = useState(false);

    const handleVideoLoad = () => {
        setVideoLoaded(true);
    };

    return (
        <div className="landing-page-testimony-card">
            <div className="landing-page-testimony-card-text-container">
                <p className="landing-page-testimony-card-name-p">{name}</p>
                <p className="landing-page-testimony-card-occupation-p">{occupation}</p>
                <div className="landing-page-testimony-video-container" onClick={handleVideoLoad}>
                    {!videoLoaded ? (
                        <>
                            <img
                                src={videoPlaceholderImage}
                                alt="Video Placeholder"
                                className="landing-page-testimony-video-placeholder"                               
                            />
                            <div 
                                style={{
                                    position: 'absolute',
                                    color: 'white',
                                    fontSize: '70px',
                                    pointerEvents: 'none' // Ensures that the click event is passed through to the image below
                                }}
                            >
                            ▶
                            </div>
                        </>
                    ) : (
                        <iframe 
                            className="landing-page-testimony-video"
                            src={`${video}?autoplay=1`}
                            title="Testimony Video"
                            frameborder="0"
                            allow="accelerometer"
                            allowfullscreen>
                        </iframe>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TestimonyCard