import React, { useState, useEffect, useCallback, useRef } from 'react';
import ProficiencyTestResults from './ProficiencyTestResults';
import CodeEditor from '../../components/QuestionScreen/CodeEditor';
import {trackMixpanel } from '../../mixpanelWrapper';
import Spinner from 'react-bootstrap/Spinner';

function ProficiencyTestQuestion({ exercises, week }) {
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
    const [showResults, setShowResults] = useState(false);
    const [userCodes, setUserCodes] = useState(exercises.map(exercise => exercise.functionDefinition));
    const [textCodeEditor, setTextCodeEditor] = useState(userCodes[currentExerciseIndex]);
    const [passAllTests, setPassAllTests] = useState(false);
    const [score, setScore] = useState(0); 
    const [timeRemaining, setTimeRemaining] = useState(60);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const timer = useRef(null);

    const handleNext = useCallback(() => {
        clearTimeout(timer.current);
        setTimeRemaining(60);
        setPassAllTests(false);
        setFeedbackMessage('');
        if (currentExerciseIndex < exercises.length - 1) {
            setCurrentExerciseIndex(currentExerciseIndex + 1);
            setTextCodeEditor(userCodes[currentExerciseIndex + 1]);
        } else {
            setShowResults(true);
        }
    }, [currentExerciseIndex, exercises.length, userCodes]);

    useEffect(() => {
        if (passAllTests) return;
        timer.current = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime > 1) return prevTime - 1;
                if (prevTime === 1) {
                    handleNext();
                    return 60; // Reset the time
                }
                return prevTime; // For safety, though this should never be reached.
            });
        }, 1000);
        return () => clearInterval(timer.current);
    }, [currentExerciseIndex, handleNext, passAllTests]);

    useEffect(() => {
        if (passAllTests) {
            setScore(score => score + 1);
            setFeedbackMessage('Fantastic! Ready for the next problem?');
            clearTimeout(timer.current);
        }
    }, [passAllTests]);

    if (showResults) {
        return <ProficiencyTestResults exercises={exercises} score={score} week={week} />
    }

    const submitQuestionToGetTests = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('code', textCodeEditor);
        formData.append('problem_name', exercises[currentExerciseIndex].title);
        formData.append('week', week);
        const response = await fetch('/run_tests_response/', {
            method: 'POST',
            body: formData
        });
        const data = await response.json();
        if (data && data.has_compiled && Object.keys(data.tests).length > 0 && data.tests[0].run === null) {
            setPassAllTests(false); 
        }
        else {
            setPassAllTests(false); 
        }
        setPassAllTests(data.passAllTests);
        setLoading(false);
    };

    function handleChange(newValue) {
        // Update the user's code for the current exercise and the text in the code editor
        setUserCodes(userCodes => {
            const newUserCodes = [...userCodes];
            newUserCodes[currentExerciseIndex] = newValue;
            return newUserCodes;
        });
        setTextCodeEditor(newValue);
    }

    function runCode(){
        trackMixpanel('Run Code');
        if (passAllTests) {
            handleNext();
        } else {
            submitQuestionToGetTests();
            setFeedbackMessage('Incorrect. Try Again!');
        }
    }
    
    return (
        <div className='proficiency-test-question-screen'>
            <h1>Proficiency Test</h1>
            <div className='proficiency-test-question-card'>
                <div className='proficiency-test-exercise-counter'>
                    <p>{currentExerciseIndex}</p>
                    <p style={{ color: 'rgba(253, 181, 0, 1)' }}>/{exercises.length}</p>
                </div>
                <div className='proficiency-test-question'>
                    <div className='proficiency-test-question-card-left'>
                        <h2 className='proficiency-test-question-left-h2'>Problem {currentExerciseIndex}</h2>
                        <h3 className='proficiency-test-question-left-h3'>{exercises[currentExerciseIndex].title}</h3>
                        <p className='proficiency-test-question-left-p' style={{ whiteSpace: "pre-wrap" }}>{exercises[currentExerciseIndex].statement}</p>
                    </div>
                    <div className='proficiency-test-question-line'></div>
                    <div className='proficiency-test-question-card-right'>
                        <div className='proficiency-test-timer'>{timeRemaining}s</div>
                        <CodeEditor value={textCodeEditor} onChange={handleChange} theme="github" />
                        {loading ? 
                                <div className="spinner-container" style={{ height: '5px', color: 'white', marginTop: '20px' }}>
                                    <Spinner animation="border" role="status"></Spinner>
                                </div>  
                            : 
                                <p className='feedback-proficiency'>{feedbackMessage}</p>
                        }
                        <div className='div-btn-msg-proficiency-question'>
                            <button className='next-test' onClick={runCode}>{passAllTests ? 'Next Problem' : 'Submit'}</button>
                        </div>
                    </div>    
                </div>
            </div>
        </div>       
    );
}

export default ProficiencyTestQuestion

