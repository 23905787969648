// Importing React and CSS styles from the App component
import React from 'react'

// A functional React component that takes in a "text" prop
function ResponseChat({ text, studentCorrectSolution }) {
    const addBackGroundToCodeBlocks = (text) => {
        // Split the text into an array of code blocks based on the backticks
        const codeBlocks = text.split('```')
        
        // Map over the array of code blocks and replace the text within the backticks with a version wrapped in a span tag
        const highlightedCodeBlocks = codeBlocks.map((block, index) => {
            if (index % 2 !== 0) {
                // This is a code block, so wrap the text in a span tag with a class for styling
                const withoutPython = block.replace(/python/g, ""); // Remove "python" from the block
                return <div key={index} className="code-block-response-chat">{withoutPython}</div>;
            } else {
                // This is not a code block, so just return the original text
                return <span key={index}>{block}</span>; // Wrap non-code text in span for consistency
            }
        })
        
        // Return the array of code blocks with the wrapped span tags
        return highlightedCodeBlocks
    }
  
    // Return a container div that may contain either a pre-formatted code block or a text explanation
    return (
      <div className="container-reponse-chat">
            <h3 className='response-chat-title'>Your AI Tutor Suggestions</h3>
            
            <div className='container-answer-from-chat'>
                {/* Render congrats message if answer is correct */}
                {studentCorrectSolution && <h6 className='answer-from-chat-text'>Congrats, your answer is correct!</h6>}
                
                {/* Renders AI answer */}
                {text && <p style={{ whiteSpace: "pre-wrap" }} className='answer-from-chat-text'>{addBackGroundToCodeBlocks(text)}</p>}
            </div>
      </div>
    );
}

// Export the ResponseChat component as the default export
export default ResponseChat