import React from 'react'
import './WeekDetailScreen.css'
import ExerciseCard from './ExerciseCard'
import ProficiencyCard from './ProficiencyCard';

function ExercisesBox({ exercises, week }) {
    
    const emptyCourseTitle = () => {
      return 'No course available';
    }

    return (
        <div className='exercises-box'>
            <div className='exercises-box-header'>
                <p>
                    {exercises.length > 0 ? 
                        exercises[0].course_name 
                    : 
                        emptyCourseTitle()
                    }
                </p>
            </div>
            <div className='exercises-box-body'>
            {exercises.length === 0 ? (
                <p>Soon we will have exercises here :)</p>
                ) : (
                exercises.map((exercise, number) => (
                    <ExerciseCard key={exercise.id} id={exercise.id} number={number+1} title={exercise.title} />
                ))
            )}  
            {exercises.length !== 0 && <ProficiencyCard week={week} />}   
            
            </div>
        </div>
    )
}

export default ExercisesBox