import React from 'react'

function MobileAlert() {
  return (
    <div className="alert alert-danger" role="alert">
        Please note that this website is optimized for desktop use.
    </div>
  )
}

export default MobileAlert