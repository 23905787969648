import mixpanel from 'mixpanel-browser';

const isLocalhost = () => {
  return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
};

export const initMixpanel = (apiKey, options) => {
  if (isLocalhost()) {
    options = {...options, disable_persistence: true, opt_out_tracking_persistence: true};
    mixpanel.init(apiKey, options);
    mixpanel.opt_out_tracking();
  } else {
    mixpanel.init(apiKey, options);
  }
};

export const identifyMixpanel = (userId) => {
  const isE2ETest = process.env.REACT_APP_IS_E2E_TEST;
  const testOS = process.env.REACT_APP_TEST_OS;

  if (!isLocalhost() && !localStorage.getItem('isDeveloper') && !(isE2ETest && testOS === 'linux')) {
    mixpanel.identify(userId);
  }
};


export const trackMixpanel = (eventName, properties) => {
  console.log("tracking" + eventName);
  const isE2ETest = process.env.REACT_APP_IS_E2E_TEST;
  const testOS = process.env.REACT_APP_TEST_OS;

  if (!isLocalhost() && !localStorage.getItem('isDeveloper') && !(isE2ETest && testOS === 'linux')) {
   mixpanel.track(eventName, properties);
  }
};