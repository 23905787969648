import Degrees from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Degrees.webp"
import TicTacToe from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/TicTacToe.webp"
import Knights from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Knights.webp"
import Minesweeper from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Minesweeper.webp"

const CS50ai = [
    {
        "title":"Project 0",
        "name":"Degrees",
        "youtubelink":"https://www.youtube.com/embed/DQQxa-vXNfU?autoplay=1",
        "placeholderimage":Degrees,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20AI/degrees?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=uVoRjp7BL1Cp"
    },
    {
        "title":"Project 0",
        "name":"Tic Tac Toe",
        "youtubelink":"https://www.youtube.com/embed/ceDspSheNdk?autoplay=1",
        "placeholderimage":TicTacToe,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20AI/tictactoe?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=uVoRjp7BL1Cp"
    },
    {
        "title":"Project 1",
        "name":"Knights",
        "youtubelink":"https://www.youtube.com/embed/KiI8AbphbSw?autoplay=1",
        "placeholderimage":Knights,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20AI/knights?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=uVoRjp7BL1Cp"
    },
    {
        "title":"Project 1",
        "name":"Minesweeper",
        "youtubelink":"https://www.youtube.com/embed/dbSCJGJVIBU?autoplay=1",
        "placeholderimage":Minesweeper,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20AI/minesweeper?kuid=0ddb883f-360a-46b7-886e-ea20249da6f3&kref=uVoRjp7BL1Cp"
    }
]

export default CS50ai