import { useState, useEffect, useCallback } from 'react';
import { trackMixpanel } from '../../../mixpanelWrapper';

// This hook is meant to encompass all the logic from your QuestionScreen component.
export function useQuestionScreen(id, user) {
    // All state declarations and other consts
    // Create state variable "text" and a function "setText" to update it
    const [textCodeEditor, setTextCodeEditor] = useState("");
    
    // Create state variable "question" and a function "setQuestion" to update it
    const [question, setQuestion] = useState("")

    // Create state variable "title" to store the title of the question
    const [title, setTitle] = useState("")

    // Create state variable "language" to store the title of the question
    const [language, setLanguage] = useState("")

    // Create state variable 'needsChatGPT' to store if the problem has unit test or needs chatGPT to evaluate
    const [needChatGPT, setNeedChatGPT] = useState(false)

    // Create state variable "currentWeek" to store the week of the exercise
    const [currentWeek, setCurrentWeek] = useState("")

    // Create state variable "exercisesList" to store the exercises of the week
    const [exercisesList, setExercisesList] = useState([])

    // Create state variable to check if problem statement is loading
    const [loadingProblemStatement, setLoadingProblemStatement] = useState(false)

    // Defining a state variable for the answer
    const [answerText, setAnswerText] = useState();

    // Defining a state variable for the tests
    const [unitTest, setUnitTest] = useState();

    // Defining a state variable for the compilationError
    const [compileErrorMessage, setCompileErrorMessage] = useState("");

    // Defining a state variable for the hasCompiledds
    const [hasCompiled, setHasCompile] = useState();

    // So we show spinner for LoadingTests
    const [loadingTests, setLoadingTests] = useState(false);

    // Show/Hide spinner for loadingCompilationError
    const [loadingCompileError, setLoadingCompileError] = useState(false);

    // Defining a state variable for the studentCorrectSolution
    const [studentCorrectSolution, setStudentCorrectSolution] = useState(false);

    // Defining a state variable to load AI message
    const [loading, setLoading] = useState(false)

    // Defining a state variable to check if AI message took too long
    const [chatTookTooLong, setChatTookTooLong] = useState(false)

    // Defining a state variable to check if pass all tests
    const [passAllTests, setPassAllTests] = useState(null)

    // Defining a state variable to calculate the progress of api call (loading bar)
    const [progress, setProgress] = useState(0);

    // At the beginning of your component, add a new state variable for the error message
    const [errorMessage, setErrorMessage] = useState('');

    // Define Functions
    /*const getLatestCode = useCallback(async () => {
        if (user) {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('email', user);
    
            const response = await fetch('/get_latest_user_code/', {
                method: 'POST',
                body: formData
            });
    
            const data = await response.json();
    
            if (data.code) {
                console.log("getLatestCode: Code found for this problem");
                return data.code; // return the code instead of setting the state here
            } else {
                console.log("getLatestCode: No code found for this problem");
                return ""; // return empty string if no code found
            }
        } else {
            console.log("getLatestCode: No user");
            return ""; // return empty string if no user
        }
    }, [id, user]);*/


    const getExercise = useCallback(async () => {
        console.log("Get Exercise: Started");
        setLoadingProblemStatement(true);
        
        
        const formData = new FormData();
        formData.append('id', id);
    
        try {
            const response = await fetch('/generate_exercise/', {
                method: 'POST',
                body: formData
            });
        
            const data = await response.json();

            if (!response.ok || data.error) {
                // Handle error here
                console.error('Error fetching exercise:', data.error);
                // You can also set some state to show the error in the UI
                setLoadingProblemStatement(false);
                return; // Early return to prevent further processing
            }
        
            //let latestCode = await getLatestCode(); // get the latest code
        
            // if there is no latest code, use the function definition from the problem
            /*if (!latestCode) {
                latestCode = data.functionDefinition + "\n    ";
            }*/
            setTextCodeEditor(data.functionDefinition + "\n    ");
            setQuestion(data.statement);
            setTitle(data.title);
            setCurrentWeek(data.week);
            setNeedChatGPT(data.needChatGPT)
            setExercisesList(data.exercises);
            setLoadingProblemStatement(false);
            setLanguage(data.language)
        } catch (error) {
            console.error('Error fetching exercise:', error);
            // Handle fetch errors or network issues here
        } finally {
            setLoadingProblemStatement(false);
        }
    
        console.log("Get Exercise: Ended");
    
    }, [id]);


    useEffect(() => {
        trackMixpanel('ProblemScreen');
        if(question === ""){
            getExercise()
        }
    }, [id, getExercise, question])

    const startProgressLoadingBar = (setProgress) => {
        const startProgress = Date.now();
        const maxAPIcallTime = 20000; // Maximum expected time for the API call
        const visualCompletionThreshold = 100; // Set a visual threshold
      
        const updateProgress = () => {
          const now = Date.now();
          const elapsed = now - startProgress;
          const progress = Math.min((elapsed / maxAPIcallTime) * visualCompletionThreshold, visualCompletionThreshold);
          setProgress(Math.round(progress));
        };
      
        return setInterval(updateProgress, 50); // Adjust interval to update more frequently
      };


    // Function to handle form submission
    const submitQuestionToChatGPT = async () => {
        // Add loading icon while waiting for ChatGPT response
        setLoading(true);
        setProgress(0);
        setErrorMessage('');
        console.log("submitQuestionToChatGPT: Loading started");

        // Step 2 and 3: Increment the progress value periodically
        const progressInterval = startProgressLoadingBar(setProgress);
    
        const formData = new FormData();
        formData.append('question', question);
        formData.append('code', textCodeEditor);
        formData.append('problem_name', title);
        formData.append('week', currentWeek);
    
        // Instantiate a new AbortController
        const controller = new AbortController();
        const signal = controller.signal;
    
        //Start time
        const startTime = performance.now();
    
        // Set a timeout to abort the request after 12 seconds
        const timeout = setTimeout(() => {
            controller.abort();
            setErrorMessage('Our AI is busy at the moment. Please try again.');
            console.log("submitQuestionToChatGPT: Request was aborted after 12 seconds");
        }, 12000);
    
        try {
            // Sending a POST request to the server with the form data
            const response = await fetch('/chatGPT_response/', {
                method: 'POST',
                body: formData,
                signal // Add the signal to the fetch call
            });
    
            clearTimeout(timeout);  // Clear the timeout if request completed successfully
            clearInterval(progressInterval);  // Clear the progress increment interval

            if (!response.ok) {
                console.error(`submitQuestionToChatGPT: Network response was not ok, status: ${response.status}, statusText: ${response.statusText}`);
                throw new Error(`Network response was not ok, status: ${response.status}`);
            }
    
            const contentType = response.headers.get('content-type');
            if (!contentType || !contentType.includes('application/json')) {
                throw new TypeError("The server response is not in JSON format.");
            }
    
            const data = await response.json();
    
            const endTime = performance.now();
            const duration = (endTime - startTime) / 1000;
            trackMixpanel('ChatGPT API Call', {
                'Duration': duration,
            });
    
            console.log(`submitQuestionToChatGPT: API call took ${duration} milliseconds`);

            console.log("submitQuestionToChatGPT: Loading completed");
    
            // Updating the answer state with the explanation
            setAnswerText(data.explanation);
    
            // Updating the studentCorrectSolution with True or False
            setStudentCorrectSolution(data.is_student_solution_correct);

            // Update the chatTookTooLong with a False because the API was successful
            setChatTookTooLong(false)

            // Step 4: Set the progress to 100% after a successful API call
            setProgress(100);

            // Remove loading icon to display the answer
            // Add a delay of 1 second before setting loading to false
            setTimeout(() => {
                setLoading(false);
            }, 500);
            
        } catch (error) {
            clearInterval(progressInterval);  // Clear the progress increment interval in case of an error
            console.error('submitQuestionToChatGPT: An error occurred:', error.name, error.message, error.stack);

            if (error.name === 'AbortError') {
                console.log('submitQuestionToChatGPT: Fetch request was aborted');
                setErrorMessage('Our AI is busy at the moment. Please try again.');
                // Update the chatTookTooLong with a True because the API exceed 12s
                setChatTookTooLong(true)
            } else {
                // Set a user-friendly error message depending on the type of error
                setErrorMessage('Our AI is busy at the moment. Please try again.');
                console.error('submitQuestionToChatGPT: An error occurred:', error);
            }
            setLoading(false); // Ensure loading is set to false if there's an error
        }
    };

    // Function to get test results
    const submitQuestionToGetTests = async () => {
        try {
            console.log("Submit Tests: Started");
            setLoadingTests(true);
            setLoadingCompileError(true);
            setPassAllTests(null);
    
            const formData = new FormData();
            formData.append('code', textCodeEditor);
            formData.append('problem_name', title);
            formData.append('week', currentWeek);
    
            const response = await fetch('/run_tests_response/', {
                method: 'POST',
                body: formData
            });
    
            const data = await response.json();
    
            setHasCompile(data.has_compiled)
            setCompileErrorMessage(data.error_message);
            setUnitTest(data.tests)
            setPassAllTests(data.passAllTests)
    
        } catch (error) {
            console.error('submitQuestionToGetTests: An error occurred:', error);
            // Add more specific error handling here as necessary
        } finally {
            setLoadingCompileError(false);
            setLoadingTests(false);
            console.log("Submit Tests: Ended");
        }
    };


    // Function to handle form submission
    /*const storeCodeSolution = async () => {
        if (user) {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('email', user)
            formData.append('code', textCodeEditor)

            const response = await fetch('/store_user_code/', {
                method: 'POST',
                body: formData
            });

            const data = await response.json();

            if(data.success === 'ok') {
                console.log(`storeCodeSolution: Code for problem ${id} was successfully stored.`);
            } else if (data.detail) {
                console.log(`storeCodeSolution: ${data.detail}`);
            } else {
                console.log(`storeCodeSolution: Unknown error occurred while trying to store the code.`);
            }
        } else {
            console.log("storeCodeSolution: Error, no user detected.");
        }
    };*/


    // Function to handle changes to the editor's value
    function handleChange(newValue) {
        // Update the "text" state variable with the new value
        setTextCodeEditor(newValue);
    }

    async function runCode() {
        // Log the event in Mixpanel
        trackMixpanel('Run Code');
        
        // Ensure we clear any previous state that might interfere with the current operation
        setErrorMessage('');
        setLoading(true);
        setProgress(0)
        
        try {
            /*
                * We use Promise.all to concurrently run asynchronous operations - submitting a question for tests (if required) and for ChatGPT evaluation. 
                * This allows both operations to execute in parallel, improving efficiency by not waiting for one to finish before starting the other. 
                * Promises ensure these non-blocking operations are managed effectively, allowing us to proceed only after both operations have completed.
             */
            const promises = [];
    
            // If the exercise requires running tests (i.e., not needing ChatGPT evaluation)
            if (!needChatGPT) {
                console.log("Submit Answer to Get Tests started");
                promises.push(submitQuestionToGetTests().then(() => console.log("Submit Answer to Get Tests finished")));
            }
    
            // Always submit the question to ChatGPT for evaluation
            console.log("Submit Answer to ChatGPT started");
            promises.push(submitQuestionToChatGPT().then(() => console.log("Submit Answer to ChatGPT finished")));
    
            // Wait for all promises to settle
            await Promise.all(promises);
    
            // Store the code solution after the above operations have completed
            // await storeCodeSolution();
        } catch (error) {
            console.error('An error occurred in runCode:', error);
            // Update UI accordingly to reflect the error
            setErrorMessage('Our AI is busy at the moment. Please try again.');
        } finally {
            // Ensure loading state is updated to stop showing any loading indicator
            setLoading(false);
        }
    }
    

    // Return anything that needs to be returned to the component
    return {
        title,
        loadingProblemStatement,
        question,
        textCodeEditor,
        handleChange,
        exercisesList,
        runCode,
        hasCompiled,
        loadingCompileError,
        loadingTests,
        compileErrorMessage,
        unitTest,
        passAllTests,
        loading,
        answerText,
        chatTookTooLong,
        studentCorrectSolution,
        needChatGPT,
        progress,
        language,
        errorMessage
    };
}
