import React from 'react'
import newCodingDorsLogo from '../../images/newCodingDorsLogo.webp';
import './NavBar.css'

function NewNavBar() {
    return (
        <nav className="new-navbar-container">
            <div className='new-navbar-items'>
                <a href='/'>
                    <img src={newCodingDorsLogo} alt="CodingDors" className='newCodingDorsIcon' />
                </a>
            </div>
        </nav>
  )
}

export default NewNavBar