import React from 'react'
import BootcampFAQCards from './BootcampFAQCards'

function BootcampFAQ() {
  const leftContent = [
    {
      'question': 'What is Dors Coding Bootcamp?', 'answer': 'Our online coding bootcamp is a comprehensive program that teaches students the skills needed to become a professional software developer. The program is structured as a series of interactive lessons, projects, and assignments, and is delivered online through Zoom and our exclusive platform.'
    },
    {
      'question': 'What Are the Schedule and Duration of Classes?', 'answer': 'Classes will be held every Tuesday and Thursday at 6pm (PST/CET) and will last for 1 and a half hours.'
    },
    {
      'question': 'What Do I Need To Know Before Starting Dors Coding Bootcamp?', 'answer': 'It is not necessary to have any prior programming experience to enroll in our online coding bootcamp. However, it can be helpful to have a strong foundation in math, as well as basic computer skills such as using a text editor and searching doubts over the internet (Google and ChatGPT).'
    },
    {
      'question': 'What Will I Learn in Dors Coding Bootcamp?', 'answer': 'In our online coding bootcamp, you will start by learning the fundamentals of programming with CS50 Python. In the second half of the program, you will continue your learning with advanced topics in CS50 and then move on to web development with CS50 Web. By the end of the course, you will have a strong foundation in software development and the projects to build your own portfolio. The languages we will work on are Python, SQL, JavaScript, HTML, and CSS. The frameworks are Flask, Django and React.'
    }
  ]

  const rightContent = [
    {
      'question': 'How Long Does Dors Coding Bootcamp Take to Complete?', 'answer': 'The bootcamp will take a total of 3 months to complete. Our syllabus outlines the dates and topics covered in each lecture, as well as the homework assignments.'
    },
    {
      'question': 'Will I Receive a Certificate Upon Completing Dors Coding Bootcamp?', 'answer': 'Yes, upon completing our online coding bootcamp, you will receive certificates for the CS50 Python and CS50 Web courses from Harvard University, recognizing your successful completion of these comprehensive software development programs.'
    },
    {
      'question': 'Will Completing Dors Coding Bootcamp Guarantee Me a Job as a Web Developer?', 'answer': "Completing our online coding bootcamp guarantees that you will find a job, because we will equip you with strong programming foundations and a portfolio of your own projects. Our students' testimonials are evidence of this."
    },
    {
      'question': 'What Is the Difference Between Dors Coding School and Free Online Courses?', 'answer': 'Dors Coding School provides structured, hands-on guidance to help you master web development skills that online courses alone may not be able to provide. With practical projects, instructors, and career support, our program will help you learn to code and prepare for the job market.'
    }
  ]

  return (
    <div className='bootcamp-faq-section'>
      <h1>Frequently Asked Questions</h1>
      <div className='bootcamp-faq-div'>
        <div className='bootcamp-faq-div-left'>
          {leftContent.map((item) => <BootcampFAQCards question={item.question} answer={item.answer} />)}
        </div>
        <div className='bootcamp-faq-div-right'>
          {rightContent.map((item) => <BootcampFAQCards question={item.question} answer={item.answer} />)}
        </div>
      </div>
    </div>
  )
}

export default BootcampFAQ