import Normals from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Normals.webp"
import Cyberchase from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Cyberchase.webp"
import Packages from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Packages.webp"
import BedAndBreakfast from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/BedAndBreakfast.webp"
import PrivateEye from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/PrivateEye.webp"
import MeteoriteCleaning from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/MeteoriteCleaning.webp"
import CensusTaker from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/CensusTaker.webp"
import Atl from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Atl.webp"
import Players from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Players.webp"
import Dese from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/Dese.webp"
import Views from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/36Views.webp"
import UnionSquareDonuts from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/UnionSquareDonuts.webp"
import DontPanic from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/DontPanic.webp"
import HappyToConnect from "../../../images/solutionsCourseCards/InnerCodeSolutionsPlaceholders/HappyToConnect.webp"


const CS50sql = [
    {
        "title":"Problem Set 0",
        "name":"Cyberchase",
        "youtubelink":"https://www.youtube.com/embed/-AVf2m6JapM?autoplay=1",
        "placeholderimage":Cyberchase,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week%200/cyberchase"
    },
    {
        "title":"Problem Set 0",
        "name":"36 Views",
        "youtubelink":"https://www.youtube.com/embed/o6RJmMP4ouc?autoplay=1",
        "placeholderimage":Views,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week%200/views"
    },
    {
        "title":"Problem Set 0",
        "name":"Normals",
        "youtubelink":"https://www.youtube.com/embed/KiI8AbphbSw?autoplay=1",
        "placeholderimage":Normals,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week%200/normals"
    },
    {
        "title":"Problem Set 0",
        "name":"Players",
        "youtubelink":"https://www.youtube.com/embed/Kh8n_Mp710E?autoplay=1",
        "placeholderimage":Players,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week%200/players"
    },
    {
        "title":"Problem Set 1",
        "name":"Packages, Please",
        "youtubelink":"https://www.youtube.com/embed/6xgLgMGvnMw?autoplay=1",
        "placeholderimage":Packages,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week1/packages"
    },
    {
        "title":"Problem Set 1",
        "name":"Dese",
        "youtubelink":"https://www.youtube.com/embed/MUcap43meDg?autoplay=1",
        "placeholderimage":Dese,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week1/dese"
    },
    {
        "title":"Problem Set 2",
        "name":"ATL",
        "youtubelink":"https://www.youtube.com/embed/jlY3gPyzs4c?autoplay=1",
        "placeholderimage":Atl,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week2/atl"
    },
    {
        "title":"Problem Set 2",
        "name":"Happy To Connect",
        "youtubelink":"https://www.youtube.com/embed/ZeLRYt-ajUA?autoplay=1",
        "placeholderimage":HappyToConnect,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week2/connect"
    },
    {
        "title":"Problem Set 2",
        "name":"Union Square Donuts",
        "youtubelink":"https://www.youtube.com/embed/swvR90vKoAk?autoplay=1",
        "placeholderimage":UnionSquareDonuts,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week2/donuts"
    },
    {
        "title":"Problem Set 3",
        "name":"Don't Panic",
        "youtubelink":"https://www.youtube.com/embed/W11HrnGFXwY?autoplay=1",
        "placeholderimage":DontPanic,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week3/dont-panic"
    },
    {
        "title":"Problem Set 3",
        "name":"Meteorite Cleaning",
        "youtubelink":"https://www.youtube.com/embed/HODiHfck1xA?autoplay=1",
        "placeholderimage":MeteoriteCleaning,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week3/meteorites"
    },
    {
        "title":"Problem Set 4",
        "name":"Census Taker",
        "youtubelink":"https://www.youtube.com/embed/IgZ-yKhb8Lc?autoplay=1",
        "placeholderimage":CensusTaker,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week4/census"
    },
    {
        "title":"Problem Set 4",
        "name":"Private Eye",
        "youtubelink":"https://www.youtube.com/embed/EwcrJPQP2Nc?autoplay=1",
        "placeholderimage":PrivateEye,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week4/private"
    },
    {
        "title":"Problem Set 4",
        "name":"Bed and Breakfast",
        "youtubelink":"https://www.youtube.com/embed/Cfu-bYgCtcY?autoplay=1",
        "placeholderimage":BedAndBreakfast,
        "githublink":"https://github.com/Dors-Coding-School/Coding/tree/main/CS50%20SQL/week4/bnb"
    },
]

export default CS50sql