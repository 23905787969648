import React from 'react'
import NavBar from '../../components/NavBar/NavBar'
import './CodeHelpPage.css'

function CodeHelpPage() {
  return (
    <div className='screen-container-codehelp'>
        <NavBar />
        <div className='div-code-help'>
            <h1 className='div-code-help-h1'>Code Help!</h1>
            <h2 className='div-code-help-h2'>Introducing our “Got Questions?” service for students needing targeted help. Here’s how it works: </h2>

            <div className='card-steps-code-help'>
                <h2>1. Record Your Issue</h2>
                <h3>Send us a <a href="https://www.loom.com/" target='_blank' rel="noreferrer">Loom</a> video to <a href="mailto:giovannasp@dorscodingschool.com"><strong>giovannasp@dorscodingschool.com</strong></a><br/> Use this recording to show your problem, including any code, error messages, and methods you’ve tried. <br/>Providing full context is crucial for effective assistance.</h3>
            </div>

            <div className='card-steps-code-help-yellow'>
                <h2>2. Get a Quote</h2>
                <h3>Within 24 hours, we will provide a quote. <br/>Our rates are <strong>$250 for a 1-hour call</strong> or <strong>$200 for a video solution</strong>, with additional hours at <strong>$150 each.</strong><br/> We’ll inform you of the required time in our response.</h3>
            </div>

            <div className='card-steps-code-help'>
                <h2>3. Receive Your Answer</h2>
                <h3>Once payment is confirmed, expect a solution within 48 hours.  <br/>This service is perfect for students who need specific questions answered rather than a full course or tutoring session.</h3>
            </div>

            <p>We do not support plagiarism. We will only take clients who have done some work or tried understand the problems before coming to us.</p>

        </div>
    </div>
  )
}

export default CodeHelpPage