import React from 'react'
import './CompilationErrorCard.css'

function CompilationErrorCard({ compileErrorMessage }) {
  return (
    <div className='compilation-error-container'>
        <h3 className='compilation-title'>Compilation Error</h3>
        <div className='compilation-text-container'>
            <h6 className='compilation-text'>{compileErrorMessage}</h6>
        </div>
    </div>
  )
}

export default CompilationErrorCard