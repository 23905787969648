import React, { useEffect, useState } from 'react'
import NavBar from '../../components/NavBar/NavBar';
import TopCanva from '../../images/salesPageTopCanva.webp'
import './SalesPage.css'
import BenefitCard from '../../components/SalesPage/BenefitCards/BenefitCards'
import SalesPageTestimonyCards from '../../components/SalesPage/TestimonyCards/SalesPageTestimonyCards'
import PricingCards from '../../components/SalesPage/PricingCards/PricingCards'
import FrequentQuestionsCards from '../../components/SalesPage/FrequentQuestionsCards/FrequentQuestionsCards'
import faqData from '../../components/SalesPage/FrequentQuestionsCards/FAQ'
import salesPage1on1Placeholder from '../../images/salesPage1on1Placeholder.webp'
import salesPageRyanTestimonyCardPlaceholder from '../../images/landingPageRyanPlaceholder.webp'
import salesPageAlexTestimonyCardPlaceholder from '../../images/landingPageAlexPlaceholder.webp'
import TomasPlaceholderImage from '../../images/Testimonials/tomas.webp'
import salesPageElieTestimonyCardPlaceholder from '../../images/landingPageEliePlaceholder.webp'
import {trackMixpanel } from '../../mixpanelWrapper';

function SalesPage() {

    useEffect(() => {
        trackMixpanel('VisitSalesPage');
    }, []);

    const handleButtonClick = () => {
        trackMixpanel('ClickToScheduleMeetingSalesPage');
    };

    const [videoLoaded, setVideoLoaded] = useState(false);

    const handleVideoLoad = () => {
        setVideoLoaded(true);
    };

    return (
    <div className='sales-page-screen'>
        <NavBar />
        <div className='sales-page-top-canva'>
            <img src={TopCanva} alt='' className='sales-page-top-canva-image'></img>
        </div>
        <div className='sales-page-top-message-box'>
            <div className='sales-page-top-message-box-text-format'>
                BECOME A PROGRAMMER <span>IN 1 YEAR OR</span> WE WILL<br />
                <span>TEACH YOU FOR FREE UNTIL YOU DO</span>
            </div>
        </div>
        <div className='sales-page-video-container' onClick={handleVideoLoad}>
            {!videoLoaded ? (
                <>
                    <img
                        src={salesPage1on1Placeholder}
                        alt="Video Placeholder"
                        className="sales-page-1-on-1-video-placeholder"                               
                    />
                    <div
                        style={{
                            position: 'absolute',
                            color: 'white',
                            fontSize: '70px',
                            pointerEvents: 'none' // Ensures that the click event is passed through to the image below
                        }}
                    >
                    ▶
                    </div>
                </>
            ) : (
                <iframe src={`https://www.youtube.com/embed/mdQccMDKxhc?autoplay=1`} className='sales-page-video' title='1-on-1' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            )}
        </div>
        <div className='sales-page-first-blue-canva'>
            <div className='sales-page-first-blue-canva-first-text'>
                Schedule a <strong>FREE 30 Minutes</strong> call with us in the link below so we can<br />
                understand your background and goals and get to know us better.
            </div>
            <div className='sales-page-first-blue-canva-schedule-button-container'>
                <a href="/schedulemeeting" target="_blank" rel="noopener noreferrer"><button onClick={handleButtonClick} className='sales-page-first-blue-canva-schedule-button'>SCHEDULE YOUR MEETING!</button></a>
            </div>
            <div className='sales-page-first-blue-canva-second-text'>
                What are the benefits you get by studying with us?
            </div>
        </div>
        <div className='sales-page-second-blue-and-white-canva-container'>
            <div className='sales-page-second-blue-and-white-canva-first-column'>
                <div className='sales-page-second-blue-and-white-canva-first-column-first-row'>
                    <BenefitCard titleColor='benefit-card-container-title-white' bodyColor='benefit-card-container-body-white' backgroundColor='benefit-card-container-white' title='400+ Coding Problems w/ Solution' body="Going from Lectures to Problem Set is often a struggle. We've created 400+ problems to ease your learning curve." />
                </div>
                <div className='sales-page-second-blue-and-white-canva-first-column-second-row'>
                    <BenefitCard titleColor='benefit-card-container-title-white' bodyColor='benefit-card-container-body-white' backgroundColor='benefit-card-container-white' title='​20+ Hours of Video Tutorials' body='We break down the hardest topics in CS50 to you: pointers, data structures, advanced Python, Django, etc.' />
                </div>
            </div>
            <div className='sales-page-second-blue-and-white-canva-second-column'>
                <div className='sales-page-second-blue-and-white-canva-second-column-first-row'>
                    <BenefitCard titleColor='benefit-card-container-title-dark' bodyColor='benefit-card-container-body-dark' backgroundColor='benefit-card-container-dark' title='Weekly 1-on-1 Tutoring' body='Get 1-on-1 tutoring over Zoom. We will help you get unstuck and give you tasks to help you keep progressing at CS50, The Odin Project and freeCodeCamp.' />
                </div>
                <div className='sales-page-second-blue-and-white-canva-second-column-second-row'>
                    <BenefitCard titleColor='benefit-card-container-title-dark' bodyColor='benefit-card-container-body-dark' backgroundColor='benefit-card-container-dark' title='Mini-Course on How to Market Yourself as a Self-Taught Developer' body='At the end of our course, we have a module where we will show you how to market yourself as a self-taught developer.' />
                </div>
            </div>
            <div className='sales-page-second-blue-and-white-canva-third-column'>
                <div className='sales-page-second-blue-and-white-canva-third-column-first-row'>
                    <BenefitCard titleColor='benefit-card-container-title-white' bodyColor='benefit-card-container-body-white' backgroundColor='benefit-card-container-white' title='​Email Support' body='We answer your questions within 24 hours. Just send us an email!' />
                </div>
                <div className='sales-page-second-blue-and-white-canva-third-column-second-row'>
                    <BenefitCard titleColor='benefit-card-container-title-white' bodyColor='benefit-card-container-body-white' backgroundColor='benefit-card-container-white' title='​​We Help You Build Your Portfolio' body='At the end of our course, we will help you build your portfolio.' />
                </div>
            </div>
        </div>
        <div className='sales-page-first-white-canva-container'>
            <div className='sales-page-first-white-canva-container-title'>Skin in the Game</div>
            <div className='sales-page-first-white-canva-container-body'>What do Bootcamps, Online Courses and Universities have in<br />common when you sign up for their course?</div>
            <div className='sales-page-first-white-canva-container-body'>As the best-seller author Nassim Nicholas Taleb likes to say, <span>they all<br />lack "skin in the game":</span> something to lose if you don't achieve your<br />goals.</div>
            <div className='sales-page-first-white-canva-container-end'>But we do.</div>
        </div>
        <div className='sales-page-third-blue-canva-container'>
            <p>We guarantee you will become a programmer in 1 year, otherwise <br /><span>we will keep teaching you for free until you do.</span></p>
            <p>That means we focus not only on teaching you how to code in a <br />timely manner but also on <span>helping you build a portfolio <br />that gets you a job.</span></p>
            <p>The reason that is possible is that we believe 1-on-1 tutoring is the <br />best way to learn any skill and that our method will get you results.</p>
            <p>We want to be as invested as you are in your learning.</p>
            <p>We believe that personalized learning is the key to making real <br />progress in programming.</p>
            <p>Unfortunately, <span>most Bootcamps and University classes are taught in <br />large groups where individual needs are often neglected.</span></p>
            <p>We believe that learning to program and transitioning into <br />a new career requires support and personalized attention.</p>
            <p>At Dor’s Coding School we will assist you not only in teaching you <br />how to code but also in building your portfolio and marketing <br />yourself as a developer.</p>
            <p>That is why we keep ourselves small. <br />We believe in quality over quantity.</p>
            <p>Here is what some of our past clients have to say about us:</p>
        </div>
        <div className='sales-page-forth-blue-canva-container'>
            <div className='sales-page-forth-blue-canva-container-top'>
                <div className='sales-page-forth-blue-canva-container-top-left'>
                    <SalesPageTestimonyCards testimonyCardsName='Ryan Robinson' testimonyCardsOccupation='Data Engineer at Microsoft' testimonyCardsVideoLink='https://youtube.com/embed/AMRzSyHFpTE' salesPageTestimonyCardsPlaceholder={salesPageRyanTestimonyCardPlaceholder} />
                </div>
                <div className='sales-page-forth-blue-canva-container-top-right'>
                    <SalesPageTestimonyCards testimonyCardsName='Alex Tsuzuki' testimonyCardsOccupation='Senior Consultant at BTS' testimonyCardsVideoLink='https://youtube.com/embed/wpQv3Sndwl8' salesPageTestimonyCardsPlaceholder={salesPageAlexTestimonyCardPlaceholder} />
                </div>
            </div>
            <div className='sales-page-forth-blue-canva-middle-x'>X</div>
            <div loading="lazy" className='sales-page-forth-blue-canva-container-bottom'>
                <div className='sales-page-forth-blue-canva-container-bottom-testimony-block'>
                    <div className='sales-page-forth-blue-canva-container-bottom-left'>
                        <SalesPageTestimonyCards testimonyCardsName='Elie Alchaer' testimonyCardsOccupation='PM at SlateTechnologies' testimonyCardsVideoLink='https://youtube.com/embed/ocxQb1l8kL4' salesPageTestimonyCardsPlaceholder={salesPageElieTestimonyCardPlaceholder} />
                    </div>
                    <div className='sales-page-forth-blue-canva-container-bottom-right'>
                        <SalesPageTestimonyCards testimonyCardsName='Tomás Coutada' testimonyCardsOccupation='Industrial Engineer ITBA' testimonyCardsVideoLink='https://www.youtube.com/embed/6WWZMQ6vu-Q' salesPageTestimonyCardsPlaceholder={TomasPlaceholderImage} />
                    </div>
                </div>
                <div className='sales-page-forth-blue-canva-container-bottom-text-block'>
                    <p><span>So why should you study with us?</span> <br />Why not attend a Bootcamp, University, or be self-taught?</p>
                </div>
            </div>
        </div>
        <div className='sales-page-second-white-canva-container'>
            <div className='sales-page-second-white-canva-black-title'>
                TIME
            </div>
            <div className='sales-page-second-white-canva-black-body'>
                <p>When it comes to coding courses, you've got a lot of options, right?</p>
                <p>Why should you consider studying with us?</p>
                <p>You can definitely learn by yourself through Youtube, Udemy, or <br />
                Books, but that will take you much more time than if you had help.</p>
                <p>In coding you get stuck. You get frustrated. You won’t learn what to <br />
                study next or when you are ready to start applying for a job. <br />
                You won’t know how to create a portfolio that will get you noticed by companies.</p>
                <p>All of this will make your journey multiple times longer than it needs to be.</p>
                <p>University is another path. However, that is the least affordable <br />
                and the longest path to a new career. <br />
                Do you really want to spend 4+ years taking multiple classes <br />
                that most often don’t lead you directly to a job?</p>
            </div>
        </div>
        <div className='sales-page-first-yellow-canva'>
            <div className='sales-page-first-yellow-canva-white-title'>
                MONEY
            </div>
            <div className='sales-page-first-yellow-canva-white-body'>
                <p>University courses cost a small fortune and Bootcamps<br />
                requires you to pay their costs upfront.</p>
                <p>Our 1-on-1 tutoring goes for 1/3 of the price of most Coding <br />
                Bootcamps in the States.</p>
                <p>Moreover, you can try the Bootcamp for 1 week for only $1. <br />
                Don't like it?You get your dollar back with 1-click.</p>
                <p>Don't have time to continue studying after 6 months? <br />
                No worries, we charge you on a month-by-month basis. <br />
                You can cancel at any time.</p>
            </div>
        </div>
        <div className='sales-page-second-white-canva-container'>
            <div className='sales-page-second-white-canva-black-title'>
                PERSONALIZATION
            </div>
            <div className='sales-page-second-white-canva-black-body'>
                <p>Have you ever had a math problem that took you ages to <br />
                understand until a tutor or a friend helped you get it <br />
                and things just clicked in a couple of seconds?</p>
                <p><strong>With a tutor, you will just learn faster</strong> without the frustration <br />
                of being in a group class where people are either going too fast or slowing you down.</p>
                <p>We go at your pace.</p>
                <p>Do you have some programming background and want to go fast? <br />
                No problem, we will go fast.</p>
                <p>You’ve never studied programming in your life and want to take things slowly? <br />
                We will explain things to you step by step.</p>
            </div>
        </div>
        <div className='sales-page-second-yellow-canva'>
            <div className='sales-page-second-yellow-canva-white-title'>
                METHOD
            </div>
            <div className='sales-page-second-yellow-canva-white-body'>
                <p>We use CS50, CS50 Python, CS50 Web to teach our students.</p>
                <p>All the slides, lectures, and problems are freely available online equipment, <br />
                so you will have a lot of support material to help along your journey.</p>
                <p>Moreover, we offer more than 500 problems with solutions <br />
                that we have created help our students learn more effectively.</p>
                <p>You will have access to all of this extra material by becoming one of our students.</p>
                <p>You can schedule your classes from <br />
                6am to 6pm (PST) Monday to Friday.</p>
            </div>
        </div>
        <div className='sales-page-fifth-blue-canva-container'>
            <div className='sales-page-fifth-blue-canva-title'>
                <p>Pricing Plan</p>
                <p>No commission fee or contract.<br />
                You can cancel anytime.</p>
            </div>
            <div className='sales-page-fifth-blue-canva-pricing-cards'>
                <div className='sales-page-fifth-blue-canva-pricing-cards-1'>
                    <PricingCards style={{ display: 'none' }} price="$400" hours='1h' height={{ height: '90%' }} cartlink='https://dorscodingschool.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Coaching-One-HourWeek-USD-Monthly&utm_source=cb-app-copy' mixedPanelTag="clickOneHour" />
                </div>
                <div className='sales-page-fifth-blue-canva-pricing-cards-2'>
                    <PricingCards price="$700" hours='2h'cartlink='https://dorscodingschool.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Coaching-Two-HoursWeek-USD-Monthly&utm_source=cb-app-copy' mixedPanelTag="clickTwoHours" />
                </div>
                <div className='sales-page-fifth-blue-canva-pricing-cards-3'>
                    <PricingCards style={{ display: 'none' }} price="$1400" hours='5h' height={{ height: '90%' }} cartlink='https://dorscodingschool.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Coaching-Five-HoursWeek-USD-Monthly&utm_source=cb-app-copy' mixedPanelTag="clickFiveHours" />
                </div>
            </div>
        </div>
        <div className='sales-page-third-yellow-canva'>
            Frequently Asked Questions
        </div>
        <div className='sales-page-sixth-blue-testimony-canva'>
            <div className='sales-page-sixth-blue-testimony-canva-left'>
                <FrequentQuestionsCards height={{ height: '360px' }} faqtitle={faqData[0].question} faqanswer={faqData[0].answer}/>
                <FrequentQuestionsCards height={{ height: '324px' }} faqtitle={faqData[1].question} faqanswer={faqData[1].answer}/>
                <FrequentQuestionsCards height={{ height: '524px' }} faqtitle={faqData[2].question} faqanswer={faqData[2].answer}/>
                <FrequentQuestionsCards height={{ height: '350px' }} faqtitle={faqData[3].question} faqanswer={faqData[3].answer}/>
            </div>
            <div className='sales-page-sixth-blue-testimony-canva-right'>
                <FrequentQuestionsCards height={{ height: '440px' }} faqtitle={faqData[4].question} faqanswer={faqData[4].answer}/>
                <FrequentQuestionsCards height={{ height: '440px' }} faqtitle={faqData[5].question} faqanswer={faqData[5].answer}/>
                <FrequentQuestionsCards height={{ height: '470px' }} faqtitle={faqData[6].question} faqanswer={faqData[6].answer}/>
                <FrequentQuestionsCards height={{ height: '455px' }} faqtitle={faqData[7].question} faqanswer={faqData[7].answer}/>
            </div>
        </div>
        <div className='sales-page-last-canva'>
            <div className='sales-page-last-canva-title'>STILL HAVE QUESTIONS?</div>
            <div className='sales-page-last-canva-body'>Schedule a <span>FREE 30 Minutes</span> call with us in the link below so we can <br />understand your background and goals and get to know us better.</div>
            <div className='sales-page-first-blue-canva-schedule-button-container'>
                <a href="/schedulemeeting" target="_blank" rel="noopener noreferrer"><button onClick={handleButtonClick} className='sales-page-last-canva-schedule-button'>SCHEDULE YOUR MEETING!</button></a>
            </div>
            <div className='sales-page-last-canva-body'>Do not miss this opportunity to finally learn how to code!</div>
        </div>
        <div className='sales-page-bottom-canva'></div>
    </div>
  )
}

export default SalesPage