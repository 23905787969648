import React from 'react'
import NavBar from '../../components/NavBar/NavBar'
import './TreePage.css'
import PythonTree from '../../components/TreePage/PythonTree'
import CardPrivate from '../../components/TreePage/CardPrivate'
// import CardQuiz from '../../components/TreePage/CardQuiz'

function PythonTreePage() {
  return (
    <div className='screen-container-tree'>
        <NavBar />
        <div className='main-screen-container'>
            <div className='main-screen-sidebar'></div>
            <div className='main-screen-tree'><PythonTree /></div>
            <div className='main-screen-right'>
                {/* <CardQuiz /> */}
                <CardPrivate />
            </div>
            </div>
    </div>
  )
}

export default PythonTreePage