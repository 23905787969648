import React from 'react'

import ButtonExercise from './ButtonNext';

function ChangeExerciseButton({ id, exercisesList }) {

  const indexExercise = exercisesList.indexOf(parseInt(id));

  let nextId =  exercisesList[parseInt(indexExercise) + 1];
  let previousId =  exercisesList[parseInt(indexExercise) - 1];
  
  return (
    <div className='container-previous-next-exercise'>
      {/* Display previous button from second exercise */}
      {indexExercise > 0 ? <ButtonExercise id={previousId} status="Previous" /> : ""}

      {/* Display next button until second to last exercise */}
      {indexExercise < exercisesList.length - 1 ? <ButtonExercise id={nextId} status="Next" /> : ""}
    </div>
  )
}

export default ChangeExerciseButton